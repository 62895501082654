import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthBackofficeGuard } from './guards/auth-backoffice.guard';
import { AuthGuard } from './guards/auth.guard';
import { AdminComponent } from './pages/admin/admin.component';
import { BackofficeComponent } from './pages/backoffice/backoffice.component';
import { ChatComponent } from './pages/chat/chat.component';
import { Error404Component } from './pages/error404/error404.component';
import { LoginComponent } from './pages/login/login.component';
import { QueueComponent } from './pages/queue/queue.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { NpsComponent } from './pages/nps/nps.component';

/**
 * Routes
 * Rotas da aplicação
 * @author Edmilson Silva <ebsilva@indracompany.com>
 */
const routes: Routes = [
  {path: 'nps', component: NpsComponent},
  { path: 'queue', component: QueueComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'teams', component: TeamsComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'backoffice', component: BackofficeComponent, canActivate: [AuthBackofficeGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'queue', pathMatch: 'full' },
  { path: 'boss-kaizala-v2', redirectTo: 'queue', pathMatch: 'full' },
  { path: '**', component: Error404Component },
];

/**
 * App Routing Modules
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
