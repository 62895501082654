// Session's statuses
/**
 * Status API
 */
export enum Status {
  // Chat is on queue
  QUEUE = 0,
  // Chat is active and anwser by bot
  BOT = 1,
  // Chat is active and anwser by human
  HUMAN = 2,
  // Chat is changing to another #channel
  CHANGETO = 3,
}
