import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ChatMessage } from '../models/chat-message';
import { ChatResult } from '../models/chat-result';
import { MessageResult } from '../models/message-result';
import { QueryParams } from '../models/query-params';
import { Result } from '../models/result';
import { User } from '../models/user';

interface ForceCloseChatData {
  action: string;
  sessionid: string;
  button?: string;
  token?: string;
}
/**
 * Operator actions services
 */
@Injectable({
  providedIn: 'root',
})
export class OperatorService {
  public init = 5;
  public first = true;
  public countdownEndSource = new Subject<number>();
  private readonly countdownSource = new BehaviorSubject<number>(0);
  private readonly apiUrl = `${environment.url}`;
  private countdownRef = null;

  constructor(
    private readonly http: HttpClient,
  ) { }

  /**
   * set user data by query string params
   * @param params QueryParams
   */
  public setUserByParams(params: QueryParams): User | boolean {
    
    if (params.senderid) {
      
      let name = params.name;

      if (!name) {
        name = params.senderid;
      }

      return new User(params.senderid, name);
    }

    return false;
  }
  /**
   * Check Operators Queue
   * @param channel string
   */
  public getQueue(channel?: string): Observable<Result> {
    const data: { action: string; canal?: string } = { action: 'getQueue' };
    if (channel) {
      data.canal = channel;
    }
    return this.http
      .post<Result>(`${this.apiUrl}/getQueue`, data)
      .pipe(
        catchError(
          this.handleError<Result>('getQueue', { ok: false, result: {} }),
        ),
      );
  }
  /**
   * check Operator's chats
   */
  public getUsersInService(operador: string): Observable<Result> {
    return this.http
      .post<Result>(`${this.apiUrl}/getChatQueue`, { action: 'getChatQueue', operador })
      .pipe(
        catchError(
          this.handleError<Result>('getUsersInService', { ok: false, result: {} }),
        ),
      );
  }
  /***
   * start a new chat
   */
  public startChat(sessionid: string, user: User): Observable<Result> {
    return this.http
      .post<Result>(`${this.apiUrl}/startChat`, { action: 'startChat', sessionid, operador: user.sigla, operadornomec: user.name });
  }
  /**
   * Send new Message
   */
  public sendMessage(sessionid: string, user: User, text: string): Observable<Result> {
    return this.http
      .post<Result>(`${this.apiUrl}/sendMessage`,
        { action: 'sendMessage', sessionid, sigla: user.sigla, nome: user.name, isoperator: true, isbot: false, text },
      )
      .pipe(
        catchError(
          this.handleError<Result>('sendMessage', { ok: false, result: {} }),
        ),
      );
  }
  /**
   * it Closes the chat
   */
  public closeChat(sessionid: string, token?: string): Observable<Result> {
    return this.http
      .post<Result>(`${this.apiUrl}/closeChat`,
        { action: 'closeChat', sessionid, token },
      )
      .pipe(
        catchError(
          this.handleError<Result>('closeChat', { ok: false, result: {} }),
        ),
      );
  }

  /**
   * it Closes the chat
   */
  public forceCloseChat(sessionid: string, text?: string, token?: string): Observable<Result> {
    const data: ForceCloseChatData = { action: 'forceCloseChat', sessionid, token };
    if (text) {
      data.button = text;
    }
    return this.http
      .post<Result>(`${this.apiUrl}/forceCloseChat`, data)
      .pipe(
        catchError(
          this.handleError<Result>('forceCloseChat', { ok: false, result: {} }),
        ),
      );
  }

  /**
   * it Closes the chat
   */
  public getIncidentData(sessionid: string): Observable<Result> {
    return this.http
      .post<Result>(`${this.apiUrl}/getIncident`,
        { action: 'getIncident', sessionid },
      )
      .pipe(
        catchError(
          this.handleError<Result>('getIncident', { ok: false, result: {} }),
        ),
      );
  }
  /**
   * send incident user data
   */
  public sendIncidentData(sessionid: string, user: User): Observable<Result> {
    return this.http
      .post<Result>(`${this.apiUrl}/sendIncidentData`,
        {
          action: 'sendIncidentData',
          sessionid,
          phone: user.phone,
          name: user.name,
          sigla: user.sigla,
        },
      )
      .pipe(
        catchError(
          this.handleError<Result>('sendIncidentData', { ok: false, result: {} }),
        ),
      );
  }

  /**
   * tranform
   * @param msgResult Array<MessageResult>
   */
  public msgToChatMessage(msgResult: Array<MessageResult>): Array<ChatMessage> {
    return msgResult.map((m) => {
      const isAttendant = !!(m.isoperator) || !!(m.isbot);
      const user = new User(m.sigla, m.nome, isAttendant);
      return new ChatMessage(user, m.text, m.datamsg);
    });
  }
  /**
   * tranform
   * @param chatResult ChatResult
   */
  public chatToChatMessage(chatResult: ChatResult): Array<ChatMessage> {
    return this.msgToChatMessage(chatResult.chat);
  }

  /**
   * Start Countdown and invoke process
   * @param init number - time to proceded CountdownEnd
   */
  public startCountdown(init?: number): void {
    this.first = true;
    if (init) {
      this.init = init;
    }
    this.process();
  }

  /**
   * Process Countdown and invoke doCountdown
   */
  public process(process = true): void {
    if (process) {
      if (this.first) {
        const firstTimer = 50;
        setTimeout(() => {
          this.countdownEndSource.next();
        }, firstTimer);
      } else if (this.init && this.init > 0) {
        this.destroy();
        this.countdownSource.next(this.init);
        this.doCountdown();
      }
      this.first = false;
    }
  }

  /**
   * Destroy and call clearTimeout
   */
  public destroy(): void {
    this.clearTimeout();
  }

  /**
   * Create Countdown Timeout
   * every second
   */
  private doCountdown(): void {
    const sec = 1000;
    const diff = 5;
    const time = this.init * sec - diff;
    if (this.countdownSource.getValue() > 0) {
      this.countdownRef = setTimeout(() => {
        this.countdownSource.next(0);
        this.processCountdown();
      }, time);
    }
  }
  /**
   * Process Countdown
   * Set Next or Continue
   */
  private processCountdown(): void {
    if (this.countdownSource.getValue() <= 0) {
      this.countdownEndSource.next();
    } else {
      this.doCountdown();
    }
  }

  /**
   * Clear Countdown Timeout
   */
  private clearTimeout(): void {
    if (this.countdownRef) {
      // tslint:disable-next-line: no-unsafe-any
      clearTimeout(this.countdownRef);
    }
    this.countdownRef = null;
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): () => Observable<T> {
    return (): Observable<T> =>
      // Declaration (error: Error | any)
      // Error -> console.log(`${operation} failed: ${error.message}`);
      of(result);
  }
}
