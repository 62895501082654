import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime, flatMap, take, tap } from 'rxjs/operators';

import { ChatMessage } from '../../models/chat-message';
import { ChatResult } from '../../models/chat-result';
import { QuickMessage } from '../../models/quick-message';
import { Result } from '../../models/result';
import { SessionResult } from '../../models/session-result';
import { Status } from '../../models/status';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';
import { ChatService } from '../../services/chat.service';
import { OnlineService } from '../../services/online.service';
import { OperatorService } from '../../services/operator.service';
import { QueueService } from '../../services/queue.service';

interface Incident {
  id: string;
  url: string;
}

interface Tab {
  sessionid: string;
  user: User;
  msg: Array<ChatMessage>;
  newMsgs?: boolean;
  disabled?: boolean;
  showModal?: boolean;
  incidentData?: Incident;
  showIncidentMsg?: boolean;
  wait?: string;
  status?: number;
}

// tslint:disable:no-object-literal-type-assertion
/**
 * Operator Screen
 */
@Component({
  selector: 'afe-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  public user: User;
  public sessionId: string;
  public check = true;
  public quickMessages = false;
  public charsLimit = 1000;
  public limited = false;
  public textareValue = '';
  public usersInService: Array<Tab> = [];
  public usersInQueue: Array<Tab> = [];
  public tabs: Array<Tab> = [];
  public quickMsgs: Array<QuickMessage> = [];
  public active: Tab;
  public online: boolean = true;

  public loading: boolean = false;
  public showErrorInc: boolean = false;
  public blockSend: boolean = false;

  private timeout: NodeJS.Timer = null;
  private readonly timer = 5;

  public subArray: Subscription[] = [];

  constructor(
    public readonly authService: AuthenticationService,
    public readonly chatService: ChatService,
    public readonly operatorService: OperatorService,
    public readonly queueService: QueueService,
    public readonly onlineService: OnlineService,
    public readonly route: Router,
  ) { }

  /**
   * ng on init data
   */
  public ngOnInit(): void {

    this.user = this.authService.userValue;
    this.quickMsgs = this.authService.quickMessages.value;

    if (!this.user) {
      
      this.subArray.push(this.authService.logout()
        .subscribe(async () => {
          await this.route.navigate(['/login']);
        }));

    } else {

      let needRun = true;
      this.operatorService.startCountdown(this.timer);

      this.subArray.push(this.onlineService.checkOnline()
        .subscribe((online) => {
          this.online = online;
          this.check = true;
          if (this.online) {
            this.operatorService.countdownEndSource.next();
          }
        })
      );

      this.subArray.push(this.operatorService.countdownEndSource
        .pipe(
          tap(() => {

            this.operatorService.process(this.online);

            this.subArray.push(this.authService.isAutheticated(this.user.sigla)
              .subscribe(async (res: Result) => {
                if (!res.ok) {
                  this.authService.removeLogin();
                  await this.route.navigate(['login']);
                }
              })
            );
          }),
          flatMap(() => this.operatorService.getUsersInService(this.user.sigla)),
          tap((data: Result) => {
            if (data) {
              const res = data.result as Array<ChatResult>;
              if (Array.isArray(res)) {
                const tabs = res
                  .map((c) => this.getTabs(c))
                  .filter((t) => !!t);
                this.usersInService = tabs;
                const active = this.active;
                if (active) {
                  const curActive = this.findItem(tabs, active.sessionid);
                  if (curActive && curActive.msg.length !== active.msg.length) {
                    this.scrollTo();
                  }
                } else {
                  this.scrollTo();
                }
                this.tabs = this.tabs.map((t) => {
                  const f = this.findItem(tabs, t.sessionid);
                  if (f) {
                    t.newMsgs = this.checkNewMsgs(t, f, active);
                    t.msg = f.msg;
                  } else {
                    this.sendSystemMsg(t.sessionid, 'Este atendimento foi finalizado');
                  }
                  return t;
                });
              }
              needRun = this.getQueue(needRun);
            }
          }),
        )
        .subscribe()
      );
    }
  }

  /**
   * ng on destroy component
   */
  public ngOnDestroy(): void {
    if (this.subArray.length > 0) {
      this.subArray.forEach(item => item.unsubscribe());
    }
  }

  /**
   * run get queue or not
   * @param needRun boolean
   */
  public getQueue(needRun: boolean): boolean {
    if (needRun) {
      this.subArray.push(this.operatorService.getQueue(this.user.channel)
        .subscribe(async (dResult: Result) => {
          if (dResult) {
            const resp = dResult.result as Array<SessionResult>;
            if (Array.isArray(resp)) {
              const before = this.usersInQueue;
              this.usersInQueue = resp.map((d: SessionResult) => {
                const tb: Tab = {
                  sessionid: d.sessionid,
                  user: new User(d.sigla, d.nomec || d.sigla),
                  wait: d.tempodeespera,
                  status: d.statusespera,
                  msg: [],
                };
                return tb;
              });
              if (before.length === 0 && this.usersInQueue.length > 0) {
                await this.playAudio();
              }
            }
          }

        })
      );
    }

    return !needRun;
  }

  /**
   * get all Tabs
   * @param c ChatResult
   */
  public getTabs(c: ChatResult): Tab {
    if (c.status === Status.CHANGETO) {
      this.sendSystemMsg(c.sessionid, 'Este Atendimento foi enviado para outro canal');
      return undefined;
    }
    const sessionid = c.sessionid;
    const user = new User(c.sigla, c.nomec || c.sigla);
    const cur = this.findItem(this.usersInService, sessionid);
    const msg = this.operatorService.chatToChatMessage(c);
    const newMsgs = !!(cur && cur.msg.length !== msg.length);
    return { sessionid, user, msg, newMsgs };
  }

  /**
   * playAudio
   */
  public async playAudio(): Promise<void> {
    const audio: HTMLAudioElement = document.querySelector('audio#queue-notification');
    if (audio) {
      try {
        await audio.play();
      } catch (e) {
        //
      }
    }
  }

  /**
   * get a Tab with a specific sessionid
   * @param tab Tab
   * @param sessionid string
   */
  public findItem(tab: Array<Tab>, sessionid: string): Tab {
    return tab.find((t) => t.sessionid === sessionid);
  }

  public checkTab(item: Tab): boolean {
    return this.tabs.some((t) => t.sessionid === item.sessionid);
  }

  /**
   * Check if there are new messages
   * @param tab Tab
   * @param curTab Tab
   * @param active Tab
   */
  public checkNewMsgs(tab: Tab, curTab: Tab, active?: Tab): boolean {
    if (!tab.newMsgs) {
      const num = 1;
      let last: ChatMessage;
      if (curTab.msg) {
        last = curTab.msg[curTab.msg.length - num];
      }
      const gtOne = this.tabs.length > 1;
      const notActive = !active || active.sessionid !== curTab.sessionid;
      const bool = tab.msg.length !== curTab.msg.length && gtOne;
      const lastMsg = !!(last !== void 0 && !last.sender.isAttendant);
      return bool && lastMsg && notActive;
    }
    return tab.newMsgs ? tab.newMsgs : false;
  }

  public close(event: MouseEvent, toRemove: Tab): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (toRemove.disabled) {
      this.removeTab(toRemove);
    } else if (window.confirm('Tem certeza que deseja encerrar este chat?')) {
      this.subArray.push(this.operatorService.closeChat(toRemove.sessionid)
        .subscribe(() => {
          this.removeTab(toRemove);
          this.scrollTo();
        })
      );
    }
  }

  public removeTab(toRemove: Tab): void {
    this.tabs = this.tabs
      .filter((t) => t.sessionid !== toRemove.sessionid);
    this.usersInService = this.usersInService
      .filter((data) => data.sessionid !== toRemove.sessionid);

    if (
      this.active.sessionid === toRemove.sessionid
      && this.tabs.length > 0
    ) {
      this.active = this.tabs[0];
    }
  }

  /**
   * Validate session data
   * @param elem Tab
   */
  public validateActive(elem: Tab): void {
    const active = this.active;
    if (active && elem.sessionid && elem.sessionid === active.sessionid) {
      elem.newMsgs = false;
    }
  }

  /***
   * Add new tab
   */
  public add(event: MouseEvent, elem: Tab): void {
    event.preventDefault();
    let ids: Array<string> = [];
    if (this.tabs && this.tabs.length > 0) {
      ids = this.tabs.map((t) => t.sessionid);
    }
    if (!ids.includes(elem.sessionid)) {
      this.tabs.push(elem);
    }

    if (
      (!this.active || !ids.includes(this.active.sessionid))
      && this.tabs.length > 0
    ) {
      this.active = this.tabs[0];
    }
    this.scrollTo();
  }

  /**
   * Change from Queue to In Service
   * @param event Event
   * @param tab Tab
   */
  public changeToService(event: Event, tab: Tab): void {
    event.preventDefault();
    event.stopPropagation();
    const timer$ = 250;
    this.subArray.push(this.operatorService.startChat(tab.sessionid, this.user)
      .pipe(debounceTime(timer$))
      .subscribe((data) => {
        if (data) {
          const index = this.usersInQueue.findIndex((u) => u.sessionid === tab.sessionid);
          if (index !== -1) {
            this.usersInQueue.splice(index, 1);
          }
          this.usersInService.push(tab);
        }
      }, (err: HttpErrorResponse) => {
        const error = err.error as Result;
        const result = 'Nao foi possivel remover o obj na fila de espera';
        if (!error.ok && error.result === result) {
          const index = this.usersInQueue.findIndex((u) => u.sessionid === tab.sessionid);
          if (index !== -1) {
            this.usersInQueue.splice(index, 1);
          }
          window.alert('Este atendimento não esta mais disponível');
        }
      }));
  }

  /**
   * toggle Modal with Snow data
   * @param elem Tab
   */
  public toggleModal(elem: Tab): void {
    const wait = 250;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      elem.showModal = !(!!elem.showModal);
      if (!elem.incidentData && this.online) {
        this.loading = true;
        this.subArray.push(this.operatorService.getIncidentData(elem.sessionid)
          .subscribe((incident) => {
            this.loading = false;
            if (incident.ok) {
              elem.incidentData = incident.result as Incident;
            }
          }));
      }
    }, wait);
  }

  /**
   * closeModal
   * @param elem Tab
   */
  public closeModal(elem: Tab): void {
    elem.showModal = false;
  }

  /**
   * sendInc
   */
  public sendInc(elem: Tab): void {
    elem.showIncidentMsg = false;
    this.blockSend = true;

    if (!elem.user || !elem.user.phone || !elem.user.name) {
      this.blockSend = false;
      this.showErrorInc = true;
      return;
    }

    this.subArray.push(this.operatorService.sendIncidentData(elem.sessionid, elem.user)
      .pipe(
        take(1),
        tap((incident: Result) => {
          this.blockSend = false;
          this.showErrorInc = true;
          if (incident.ok) {
            elem.showIncidentMsg = true;
            this.showErrorInc = false;
          }
        }),
      )
      .subscribe()
    );
  }

  /**
   * Copy SNow link
   */
  public copy(): void {
    const input: HTMLInputElement = document.querySelector('input#link');
    input.select();
    document.execCommand('copy');
  }

  /**
   * Access SNow link
   */
  public goTo(uri: string): void {
    if (uri) {
      window.open(uri, '_blank');
    }
  }

  /**
   * Send a Message to Chat Area
   * @param event Event
   */
  public sendMessage(event: Event, sessionid: string): void {
    event.stopPropagation();
    event.preventDefault();

    this.check = true;
    const input: HTMLTextAreaElement = document.querySelector('textarea#sendMessage');
    let text = '';
    if (input && input.value) {
      text = input.value;
    }
    if (text && text.trim() !== '' && this.online) {
      if (text.length > this.charsLimit) {
        text = text.substring(0, this.charsLimit);
      }
      this.operatorService.sendMessage(sessionid, this.user, text)
        .subscribe((data) => {
          const chatResult = data.result as ChatResult;
          const index = this.tabs.findIndex((i) => i.sessionid === sessionid);
          if (index !== -1) {
            const msgs = this.operatorService.chatToChatMessage(chatResult);
            this.tabs[index].msg = msgs;
            this.usersInService[index].msg = msgs;
            this.scrollTo();
          }
        });
      if (input && input.value) {
        input.value = '';
      }
    } else {
      this.check = false;
    }
    this.limited = false;
    if (input) {
      input.focus();
    }
    this.scrollTo();
  }

  /**
   * Scroll to Bottom Chat Area
   */
  public scrollTo(): void {
    const timeout = 50;

    setTimeout(() => {
      const chat = document.querySelector('.messages');
      const qMsgs = '.messages > afe-chat-message';
      const msgs = document.querySelectorAll(qMsgs);
      const lastMsg = (Array.from(msgs)).pop();
      if (lastMsg) {
        const value = lastMsg.getBoundingClientRect().bottom
          + lastMsg.getBoundingClientRect().height;
        chat.scrollTop += value;
      }
    }, timeout);
  }

  /**
   * sendSystemMsg
   * @param text string
   */
  public sendSystemMsg(sessionid: string, text: string): void {
    const sessionids = this.tabs.map((tab) => tab.sessionid);
    if (sessionids.includes(sessionid)) {
      const item = sessionids.indexOf(sessionid);
      const last = this.tabs[item].msg.length - 1;
      const systemReg = 'XXXXXXX';
      if (this.tabs[item].msg.length === 0 || this.tabs[item].msg[last].sender.sigla !== systemReg) {
        const user = new User(systemReg, 'Smart Help');
        const nMsg = new ChatMessage(user, text);
        this.tabs[item].msg.push(nMsg);
        if (this.active && this.active.sessionid !== this.tabs[item].sessionid) {
          this.tabs[item].newMsgs = true;
        }
        this.tabs[item].disabled = true;
      }
    }
  }

  /**
   * toggle Quick Messages (show|hide)
   */
  public toggleQuickMessages(): void {
    this.quickMessages = !this.quickMessages;
  }

  /**
   * quick Message
   * @param event Event
   */
  public quickMsg(event: Event, sessionid: string): void {
    event.stopPropagation();
    event.preventDefault();

    const msg = event.target as HTMLElement;
    const input: HTMLTextAreaElement = document.querySelector('textarea#sendMessage');
    if (input && msg && msg.title && this.online) {
      input.value = msg.title;
      this.sendMessage(event, sessionid);
    } else {
      this.check = false;
    }
    this.quickMessages = false;
  }

  /**
   * track -> by sessionId
   * @param _index number
   * @param elem Tab
   */
  public track(_index: number, elem: Tab): string {
    return elem.sessionid;
  }

  /**
   * trackByMsg -> by Msg
   * @param _index number
   * @param elem ChatMessage
   */
  public trackByMsg(_index: number, msg: ChatMessage): number {
    return new Date(msg.date).getTime();
  }

  /**
   * Send Message if the user uses <Ctrl+Enter> or <Enter> on textarea
   */
  public triggerFn(event: KeyboardEvent, sessionid: string): void {
    event.stopPropagation();
    const e: Event = event;
    const txt = e.target as HTMLTextAreaElement;
    this.limited = false;
    this.check = true;
    if (txt.value.length >= this.charsLimit) {
      this.limited = true;
      this.check = false;
    }
    const nums = ['107', '101', '121'];
    const items: Array<number> = nums.map((ev) => parseInt(ev, 10));
    const item = String.fromCharCode(...items);
    const eventClick = event[item] === 'Enter';
    if (
      event.ctrlKey && eventClick
      || !event.shiftKey && eventClick
    ) {
      this.sendMessage(event, sessionid);
    }
  }

}
