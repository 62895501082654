import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';

import { QueryParams } from '../../models/query-params';
import { Result } from '../../models/result';
import { SessionResult } from '../../models/session-result';
import { Status } from '../../models/status';
import { User } from '../../models/user';
import { ChatService } from '../../services/chat.service';
import { OnlineService } from '../../services/online.service';
import { OperatorService } from '../../services/operator.service';
import { QueueService } from '../../services/queue.service';

/**
 * Queue Component Page
 */
@Component({
  selector: 'afe-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss'],
})
export class QueueComponent implements OnInit, OnDestroy {
  public position: number = 1;
  public waitTime: number = 0;
  public user: User;
  public sigla: string;
  public error = false;
  public queue: Array<User>;
  public sessionId: string;
  public token: string;
  public protocol: string;
  public disabled = false;
  public inSession = false;
  public message = false;
  public redLine = false;
  public protocolForm: FormGroup;
  public qrcodeShow = false;
  public online = false;
  public readonly QRCODE_TOKEN = 'ac4986e644229c589b54273c53a479d53bd7a1ca';
  public readonly BASE_URI = 'https://boss.santander.com.br/';

  // set interval to refresh page in (queue)
  private interval = null;
  private readonly timer = 5;

  // time to refresh queue page (3 minutes)
  private readonly timerRefresh = 180000;

  private arraySub: Subscription[] = [];

  constructor(
    public readonly queueService: QueueService,
    public readonly operatorService: OperatorService,
    public readonly chatService: ChatService,
    public readonly onlineService: OnlineService,
    public readonly route: Router,
    private readonly aroute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
  ) { }

  /**
   * ngOnInit
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public ngOnInit(): void {
    this.protocolForm = this.formBuilder.group({
      protocol: ['', Validators.required],
    });

    this.arraySub.push(this.onlineService.checkOnline()
      .subscribe((online) => {
        this.online = online;
        if (!this.message && this.online) {
          this.operatorService.countdownEndSource.next();
        }
      })
    );

    this.sigla = localStorage.getItem('senderid');

    this.arraySub.push(this.aroute.queryParams
      .pipe(
        flatMap((params: QueryParams) => {
         
          this.disabled = false;
          this.inSession = false;
          this.token = params.token;

          if (params.ssid) {
            
            return this.queueService.startSession({
              ...{
                user: params.ssid,
                token: this.token,
                subject: params.subject
              }
            });

          } else {

            const user = this.operatorService.setUserByParams(params);

            if (user === false) {
              this.disabled = true;
            } else {
              this.user = user as User;
              localStorage.setItem('senderid', this.user.sigla);
            }
  
            return this.queueService.startSession({
              ...{
                user: this.user,
                token: this.token,
                subject: params.subject
              }
            });
  
          }

        }),
        flatMap(async (data) => {

          const sessionResult = data.result as SessionResult;

          this.protocol = sessionResult.protocol;

          if (sessionResult.sessionid) {
            this.sessionId = sessionResult.sessionid;
          }

          this.interval = setInterval(() => {
            window.location.reload();
          }, this.timerRefresh);

          if (!data.ok) {
            this.inSession = true;
            return;
          }

          if (
            (Array.isArray(sessionResult) && sessionResult.length === 0) ||
            (!Array.isArray(sessionResult) && [Status.BOT, Status.HUMAN].includes(sessionResult.status))
          ) {

            await this.redirectTo('chat');

          } else {

            if ([Status.QUEUE, Status.CHANGETO].includes(sessionResult.status)) {
              this.position = sessionResult.pos;
              this.operatorService.startCountdown(this.timer);
              this.arraySub.push(this.operatorService.countdownEndSource
                .pipe(
                  tap(() => {
                    this.arraySub.push(this.queueService.getQueue(this.sessionId, this.token)
                      .pipe(
                        tap(async (d: Result) => {
                          this.operatorService.process(!this.message && this.online);
                          await this.validate(d.result as SessionResult);
                        },
                          async (err: HttpErrorResponse) => {
                            const errorMsg = err.error as Result;
                            const emptyQueue = 'Nao foi possivel coletar a lista da fila de espera';
                            if (!errorMsg.ok && errorMsg.result === emptyQueue) {
                              await this.redirectTo('chat');
                            }
                          }),
                      )
                      .subscribe()
                    );
                  }),
                )
                .subscribe()
              );
            }
          }
        }),
      )
      .subscribe()
    );

  }

  /**
   * ngOnDestroy
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public ngOnDestroy(): void {
    if (this.arraySub.length > 0) {
      this.arraySub.forEach(item => item.unsubscribe())
    }
  }

  public changeMsgProtocol(): boolean {
    return !!this.protocol && this.protocol.endsWith('***');
  }

  /**
   * f
   * Getter to Form Fields
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.protocolForm.controls;
  }

  /**
   * valiade session result
   * @param session SessionResult | Array<SessionResult>
   */
  public async validate(session: SessionResult | Array<SessionResult>): Promise<void> {
    if (session && !Array.isArray(session) && session.status >= 0) {
      if ([Status.QUEUE, Status.CHANGETO].includes(session.status)) {
        this.position = session.pos;
        if (session.tempoatendimento) {
          this.waitTime = session.tempoatendimento;
        }
      } else if ([Status.BOT, Status.HUMAN].includes(session.status)) {
        await this.redirectTo('chat');
      }
    }
  }

  /**
   * Redirect to Chat or Queue page
   */
  public async redirectTo(url: 'chat' | 'queue'): Promise<void> {
    if (this.interval) {
      clearTimeout(this.interval);
    }
    await this.route.navigate([url], {
      queryParams: this.qs(),
    });
  }

  /**
   * Closes de Chat action
   * @param text string
   * @param url string
   */
  public closeSession(text: string, url: string): void {
    if (url !== '#') {
      this.forceCloseChat(text);
      window.open(url, '_blank');
    } else {
      if (window.confirm('Você tem certeza que deseja sair da Fila?')) {
        this.forceCloseChat();
      }
    }
  }

  public isMobile(): boolean {
    return /Mobi/i.test(window.navigator.userAgent);
  }

  /**
   * Force close the chat action
   * @param text string
   */
  public forceCloseChat(text?: string): void {
    this.arraySub.push(this.operatorService.forceCloseChat(this.sessionId, text, this.token)
      .subscribe(() => {
        this.message = true;
      })
    );
  }
  /**
   * Check the current session
   */
  public async checkSession(): Promise<void> {
    this.error = false;
    // tslint:disable-next-line: no-unsafe-any
    const protocol: string = this.f.protocol.value;
    this.queueService.checkSession(protocol, this.user.sigla)
      .subscribe(async (res: Result) => {
        if (res.ok) {
          const result = res.result as SessionResult;
          if (result.sessionid) {
            this.sessionId = result.sessionid;
          }
          await this.redirectTo('queue');
        } else {
          this.error = true;
        }
      }, () => {
        this.error = true;
      });
  }

  /**
   * Show the Qrcode Modal
   */
  public qrcode(): void {
    this.qrcodeShow = true;
  }
  /**
   * Closes the Qrcode Modal
   */
  public closeQrcode(): void {
    this.qrcodeShow = false;
  }

  public get uri(): string {
    let qs = new URLSearchParams();
    this.aroute.queryParams
      .subscribe((params) => {
        qs = new URLSearchParams({
          ...params,
          ...{ token: this.QRCODE_TOKEN },
        });
      });
    let query = qs.toString();
    if (!query) {
      const init = {
        ...this.qs(),
        ...{ token: this.QRCODE_TOKEN },
      };
      query = new URLSearchParams(init).toString();
    }
    return `${this.BASE_URI}?${query}`;
  }

  /**
   * Query String parameters
   */
  public qs(): QueryParams {
    const qs = new QueryParams();
    qs.ssid = this.sessionId;
    qs.token = this.token;
    return qs;
  }

}
