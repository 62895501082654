import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { flatMap, take, tap } from 'rxjs/operators';

import { CanalResult } from '../../models/canal-result';
import { ChatMessage } from '../../models/chat-message';
import { ChatResult } from '../../models/chat-result';
import { QueryParams } from '../../models/query-params';
import { Result } from '../../models/result';
import { Status } from '../../models/status';
import { User } from '../../models/user';
import { ChatService } from '../../services/chat.service';
import { OnlineService } from '../../services/online.service';
import { OperatorService } from '../../services/operator.service';
import { QueueService } from '../../services/queue.service';

/**
 * Main Chat Component
 */
@Component({
  selector: 'afe-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  public messages: Array<ChatMessage> = [];
  public user: User;
  public sessionId: string;
  public token: string;
  public subject: string;
  public check = true;
  public charsLimit = 1000;
  public limited = false;
  public textareValue = '';
  public disabled = false;
  public newMessages = false;
  public first = true;
  public online = true;
  public rating = 0;
  public enabled = false;
  public showSurvey = false;
  public showSurveyFade = false;
  public surveyError = false;
  public surveyMsg = false;

  private channel = false;
  private curChannel = 'ROBO';
  private readonly timer = 5;
  private arraySub: Subscription[] = [];

  constructor(
    public readonly chatService: ChatService,
    public readonly queueService: QueueService,
    public readonly operatorService: OperatorService,
    public readonly onlineService: OnlineService,
    public readonly route: Router,
    private readonly aroute: ActivatedRoute,
  ) { }

  /**
   * ngOnInit
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public ngOnInit(): void {
    this.disabled = false;
    this.aroute.queryParams.subscribe();

    this.arraySub.push(this.chatService.chatMessage
      .subscribe((m) => this.messages = m));

    this.isConnected();

    this.arraySub.push(this.aroute.queryParams
      .pipe(
        flatMap(async (params: QueryParams) => {
          this.token = params.token;
          if (!params.ssid) {
            await this.route.navigate(['queue']);
          }
          this.sessionId = params.ssid;
          this.subject = params.subject;
        }),
        flatMap(() => this.queueService.startSession({ ...{ user: this.sessionId, token: this.token, subject: this.subject } })),
        flatMap(async (data) => {
          const chatResult = data.result as ChatResult;
          await this.redirectToQueue(
            this.operatorService.setUserByParams({
              senderid: chatResult.sigla,
              name: chatResult.nomec,
            }));
          if ([Status.QUEUE, Status.CHANGETO].includes(chatResult.status)) {
            await this.redirectToQueue();
          } else if ([Status.BOT, Status.HUMAN].includes(chatResult.status)) {
            this.sessionId = chatResult.sessionid;
            this.operatorService.startCountdown(this.timer);

            this.arraySub.push(this.operatorService.countdownEndSource
              .pipe(
                tap(() => {

                  this.operatorService.process(!this.channel && !this.disabled && this.online);

                  this.arraySub.push(this.chatService.getMessages(this.sessionId, this.token)
                    .subscribe((resultMsg) => {
                      const channel = resultMsg.result as CanalResult;
                      if (this.checkRedirect(channel)) {
                        return;
                      }
                      const curMsg = this.chatService.chatMessage.value;
                      const msg = resultMsg.result as ChatResult;
                      if (curMsg.length < msg.chat.length) {
                        const messages = this.operatorService.chatToChatMessage(msg);
                        this.chatService.chatMessage.next(messages);
                        if (this.first) {
                          this.scrollTo();
                          this.first = false;
                        } else {
                          this.newMessages = true;
                          this.playAudio()
                            .then()
                            .catch();
                        }
                      }
                    }, (err: HttpErrorResponse) => {
                      const error = err.error as Result;
                      const result = 'Nao foi possivel encontrar as mensagens desse atendimento';
                      if (!this.channel && !error.ok && error.result === result && !this.disabled) {
                        const text = `Voltar a falar com **Smart Help**`;
                        this.sendSystemMsg(text);
                        this.showSurvey = true;
                        this.disabled = true;
                      }
                    })
                  );
                }),
              ).subscribe()
            );
          }
        }),
      ).subscribe()
    );
  }

  public async playAudio(): Promise<void> {
    const audio: HTMLAudioElement = document.querySelector('audio#notification');
    if (audio) {
      try {
        audio.muted = false;
        await audio.play();
      } catch (e) {
        //
      }
    }
  }

  /**
   * check if is connected
   */
  public isConnected(): void {
    this.arraySub.push(this.onlineService.checkOnline()
      .subscribe((online) => {
        this.online = online;
        if (!this.channel && !this.disabled && this.online) {
          this.check = true;
          this.operatorService.countdownEndSource.next();
        }
      }));
  }

  /**
   * ngOnDestroy
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public ngOnDestroy(): void {
    if (this.arraySub.length > 0) {
      this.arraySub.forEach(item => item.unsubscribe())
    }
  }

  /**
   * Redirect to Queue page
   */
  public async redirectToQueue(user: boolean | User = false): Promise<void> {
    if (!user) {
      await this.route.navigate(['queue'], {
        queryParams: this.qs(),
      });
    } else {
      this.user = user as User;
    }
  }

  public checkRedirect(result: CanalResult): boolean {
    if ([Status.QUEUE, Status.CHANGETO].includes(result.status)) {
      if (this.curChannel !== result.canal) {
        this.channel = true;
        this.curChannel = result.canal;
      }
      if (this.channel) {
        const textMsg = 'Você será encaminhado para a área que seguirá com seu atendimento, por favor aguarde';
        this.sendSystemMsg(textMsg);
        this.disabled = true;
        const timeout = 3000;
        setTimeout(async () => {
          await this.route.navigate(['queue'], {
            queryParams: this.qs(),
          });
          this.chatService.chatMessage.next([]);
        }, timeout);
        return true;
      }
    }
    return false;
  }

  /**
   * sendSystemMsg
   * @param text string
   */
  public sendSystemMsg(text: string): void {
    const user = new User('XXXXXXX', 'Smart Help');
    const nMsg = new ChatMessage(user, text);
    const msg = this.chatService.chatMessage.value;
    msg.push(nMsg);
    this.chatService.chatMessage.next(msg);
    this.scrollTo();
  }

  /**
   * Send a Message to Chat Area
   * @param event Event
   */
  public sendMessage(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.disabled) {
      return;
    }

    this.check = true;
    const input: HTMLTextAreaElement = document.querySelector('textarea#sendMessage');
    let text = '';

    if (input && input.value) {
      text = input.value;
    }

    if (text && text.trim() !== '' && this.online) {

      if (text.length > this.charsLimit) {
        text = text.substring(0, this.charsLimit);
      }

      input.value = '';

      this.arraySub.push(this.chatService.sendMessage(this.sessionId, this.user, text, this.token)
        .subscribe((res) => {
          const resChat = res.result as ChatResult;
          if (resChat.chat) {
            const msgs = this.operatorService.chatToChatMessage(resChat);
            this.chatService.chatMessage.next(msgs);
            this.scrollTo();
          }
          input.focus();
        })
      );

    } else {
      this.check = false;
    }
    this.limited = false;
  }

  /**
   * onScroll
   * elem: HTMLElement
   */
  public onScroll(elem: HTMLElement): void {
    const pos = elem.scrollTop + elem.offsetHeight;
    const max = elem.scrollHeight;
    const last = 20;
    if (pos + last >= max) {
      this.newMessages = false;
    }
  }

  /**
   * Scroll to Bottom Chat Area
   */
  public scrollTo(): void {
    const timeout = 150;
    this.newMessages = false;

    setTimeout(() => {
      const chat = document.querySelector('#messages');
      const qMsgs = '#messages > afe-chat-message';
      const msgs = document.querySelectorAll(qMsgs);
      const lastMsg = (Array.from(msgs)).pop();
      if (lastMsg) {
        const value = lastMsg.getBoundingClientRect().bottom
          + lastMsg.getBoundingClientRect().height;
        chat.scrollTop += value;
      }
    }, timeout);
  }

  public sendSurvey(): void {
    if (this.enabled) {
      this.enabled = false;
      this.surveyError = false;
      this.surveyMsg = false;
      this.arraySub.push(this.chatService.sendSurvey(this.rating, this.sessionId, this.user)
        .pipe(
          take(1),
          tap((res) => {
            this.surveyMsg = true;
            this.surveyError = false;
            if (!res.ok) {
              this.surveyError = true;
              this.enabled = true;
            } else {
              this.showSurveyFade = true;
              const hiddenSurvey = 3000;
              setTimeout(() => {
                this.showSurvey = false;
              }, hiddenSurvey);
            }
          }),
        )
        .subscribe()
      );
    }
  }
  /**
   * Send Message if the user uses <Ctrl+Enter> or <Enter> on textarea
   */
  public triggerFn(event: KeyboardEvent): void {
    event.stopPropagation();
    if (this.disabled) {
      return;
    }
    const e: Event = event;
    const txt = e.target as HTMLTextAreaElement;
    this.limited = false;
    this.check = true;
    if (txt.value.length >= this.charsLimit) {
      this.limited = true;
      this.check = false;
    }
    const nums = '107|101|121'.split('|');
    const eventItems: Array<number> = nums.map((ev) => parseInt(ev, 10));
    const eventItem = String.fromCharCode(...eventItems);
    const eventClick = event[eventItem] === 'Enter';
    if (
      event.ctrlKey && eventClick
      || !event.shiftKey && eventClick
    ) {
      this.sendMessage(event);
    }
  }

  /**
   * query string
   */
  public qs(): QueryParams {
    const qs = new QueryParams();
    qs.ssid = this.sessionId;
    qs.token = this.token;
    return qs;
  }

  /**
   * trackBy index
   */
  public track(_index: number, item: ChatMessage): number {
    return new Date(item.date).getTime();
  }
}
