<afe-header></afe-header>
<div class="text-center">
  <div class="first">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
    </svg>
    <span class="status-code">404</span>
  </div>
  <h1>
    Página não encontrada!
  </h1>
</div>
