
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NpsChat } from 'src/app/models/nps-chat';
import { NpsMessage } from 'src/app/models/nps-message.model';
import { NpsService } from 'src/app/services/nps.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'afe-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.scss']
})
export class NpsComponent implements OnInit {

  public invalidMessage = false;
  public messages: Array<NpsMessage> = [];
  public sessionId;
  public finished = false;
  constructor(private route: ActivatedRoute, private npsService: NpsService, public readonly sanatizer: DomSanitizer) {
  }

  public ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.sessionId = params['ssid'];
      if (this.sessionId) {

        this.npsService.startSession(this.sessionId).subscribe((response) => {
          if (response.ok.toString() !== "false") {

            let npsResult = response.result as NpsChat;
            this.finished = false;
            this.messages = npsResult.messages;
            this.scrollToBottom();
          } else {
            this.finished = true;
          }
        });

      } else { //Quando não recebe o parametro ssid na query URL.

        this.finished = true;
      }
    });
  }

  public ngAfterViewChecked(): void {
    let arrayOfButtons = document.querySelectorAll("#messages button");
    arrayOfButtons.forEach((item) => {
      let button = item as HTMLButtonElement;
      button.addEventListener('click', () => {
        const text = button.textContent;
        const sendButton = document.getElementById("sendButton") as HTMLButtonElement;
        let textarea = document.getElementById("sendMessage") as HTMLTextAreaElement;
        textarea.value = text;
        sendButton.click();

      })
    })
  }

  trustHTML(text: string): SafeHtml {
    return this.sanatizer.bypassSecurityTrustHtml(text);
  }

  async sleep(ms) {
    return new Promise(function (resolve) {
      setTimeout(resolve, ms);
    });
  }

  sendMessage($event ?: any): void {
    const input: HTMLTextAreaElement = document.querySelector('textarea#sendMessage');
    let message = input.value;

    if (message && message.trim()) {
      this.invalidMessage = false;
      this.npsService.sendMessage(this.sessionId, message).subscribe(async (response) => {

        if (response.ok.toString() !== "false") {
          let npsResult = response.result as NpsChat;
          input.value = '';
          this.messages = npsResult.messages;
          this.scrollToBottom();

          if (npsResult.finished) {

            await this.sleep(5000);
            this.finished = true;
          }

        } else {
          this.finished = true;
        }
      });
    }
    else {
      input.value = '';
      this.invalidMessage = true;
    }
  }

  public scrollToBottom(): void {
    setTimeout(() => {
      const scrollContainter = document.querySelector(".scrollbar");
      scrollContainter.scrollTop = scrollContainter.scrollHeight;
    }, 0)
  }

}
