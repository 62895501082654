import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { FormBase, KeyValueColor } from '../../models/form-base';
import { User } from '../../models/user';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BackofficeService, Settings } from '../../services/backoffice.service';

/**
 * Channel settings form
 */
@Component({
  selector: 'afe-form-channel',
  templateUrl: './form-channel.component.html',
  styleUrls: ['./form-channel.component.scss'],
})
export class FormChannelComponent extends FormBase implements OnInit, OnDestroy {

  public channelForm: FormGroup;
  public colors: Array<string>;
  public channelReset: { canais: Array<Settings>; cores: Array<Settings> };

  public channelSub: Subscription;
  public setChannel: Subscription[] = [];

  public loading = true;
  private timeout: NodeJS.Timer = null;
  private readonly user: User;

  constructor(
    public readonly formBuilder: FormBuilder,
    public readonly backOfficeService: BackofficeService,
    public readonly alertService: AlertService,
    public readonly authService: AuthenticationService,
  ) {
    super();
    this.user = this.authService.userValue;
  }
  /**
   * when the view starts
   */
  public ngOnInit(): void {
    this.channelSub = this.backOfficeService.getChannel(this.user)
      .pipe(
        take(1),
      )
      .subscribe((configs) => {
        const colors = configs.cores as Array<{ [key: string]: string }>;
        const channelArr = this.formBuilder.array(configs.canais as Array<string>);
        const colorArr = this.formBuilder.array(colors);
        this.colors = colors.map((c) => c.color);

        this.channelForm = this.formBuilder.group({
          canais: this.formBuilder.control(channelArr, Validators.required),
          cores: this.formBuilder.control(colorArr, Validators.required),
        });

        this.channelReset = {
          canais: configs.canais as Array<Settings>,
          cores: configs.cores as Array<{ [key: string]: string }>,
        };
        this.loading = false;
      }, () => { this.loading = false; });
  }
  /**
   * when the view is destroyed
   */
  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
    if (this.channelSub) {
      this.channelSub.unsubscribe();
    }

    if (this.setChannel.length > 0) {
      this.setChannel.forEach(item => item.unsubscribe());
    }
  }

  /**
   * Save channel settings data
   */
  public saveChannel(): void {
    this.alertService.clear();
    const min = 0;
    const max = 7;
    const cores = ((this.channelForm.controls.cores.value as FormArray).value as Array<KeyValueColor>
    ).map((v) => ({ ...v, ...{ color: v.color.substring(min, max) } }));

    const data = {
      canais: (this.channelForm.controls.canais.value as FormArray).value,
      cores,
    };

    this.setChannel.push(this.backOfficeService.setChannel(this.user, data)
      .pipe(
        take(1),
      )
      .subscribe(() => {

        this.colors = cores.map((c) => c.color);

        this.channelReset = {
          canais: data.canais as Array<Settings>,
          cores,
        };

        this.loading = false;
        this.alertService.success('Configurações de canais salva');
        this.timeout = this.timeoutClearMsg(this.alertService);

      }, () => {

        this.loading = false;
        this.alertService.error('Erro ao salvar configurações de canais');
        this.timeout = this.timeoutClearMsg(this.alertService);
      }));
  }

  /**
   * reset Channel settings
   */
  public resetChannel(): void {
    const reset = [].concat(this.channelReset.cores);
    this.channelReset.cores = reset.map((v, k) =>
      ({ ...v, ...{ color: this.colors[k] } }));

    this.channelForm.controls.cores.setValue(
      this.formBuilder.array(this.channelReset.cores),
    );
    this.channelForm.controls.canais.setValue(
      this.formBuilder.array(this.channelReset.canais),
    );
  }

}
