import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { LoginResult } from '../models/login-result';
import { QuickMessage } from '../models/quick-message';
import { Result } from '../models/result';
import { User } from '../models/user';

/**
 * AuthenticationService
 * Serviço de autenticação
 * @author Edmilson Silva <ebsilva@indracompany.com>
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public user: Observable<User>;
  public lastChannel = new BehaviorSubject<string>('#SMART');
  public quickMessages = new BehaviorSubject<Array<QuickMessage>>([]);
  private readonly USER_KEY = 'userData';
  private readonly QUICK_MSG = 'quickMessages';
  private readonly LAST_CHANNEL = 'lastChannel';
  private readonly userSubject: BehaviorSubject<User>;
  private readonly apiUrl = `${environment.url}`;

  constructor(
    public readonly http: HttpClient,
  ) {
    this.userSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem(this.USER_KEY)) as User,
    );
    const msgs = JSON.parse(localStorage.getItem(this.QUICK_MSG));
    if (Array.isArray(msgs)) {
      this.quickMessages.next(msgs as Array<QuickMessage>);
    }
    this.user = this.userSubject.asObservable();
    this.lastChannel.next(localStorage.getItem(this.LAST_CHANNEL) || '#SMART');
  }

  /**
   * Get userToken Value
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public get userValue(): User {
    const token = localStorage.getItem(this.USER_KEY);
    if (token && this.userSubject.value) {
      return this.userSubject.value;
    }
    return null;
  }

  /**
   * setToken
   * set token Value
   * @author Edmilson Silva <ebsilva@indracompany.com>
   *
   * @param newToken string
   */
  public setToken(user: User): void {
    if (!user) {
      this.setUser(null);
      return;
    }
    let oldUser = JSON.parse(localStorage.getItem(this.USER_KEY)) as User;
    if (!oldUser || !oldUser.sigla) {
      this.setUser(user);
      oldUser = user;
    }
    if (user.sigla !== oldUser.sigla) {
      this.setUser(user);
    }
  }
  public setQuick(): void {
    localStorage.setItem(this.QUICK_MSG, JSON.stringify(this.quickMessages.value));
  }

  public setUser(user: User): void {
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    this.userSubject.next(user);
    this.lastChannel.next((user && user.channel) || '#SMART');
    localStorage.setItem(this.LAST_CHANNEL, this.lastChannel.value);
  }

  /**
   * Call Auth login and set new userToken
   * @author Edmilson Silva <ebsilva@indracompany.com>
   *
   * @param username string
   */
  public login(username: string, password: string, channel = '#SMART'): Observable<User> {
    return this.http.post<Result>(
      `${this.apiUrl}/login`,
      { action: 'login', usuario: username, senha: encodeURIComponent(password), canal: channel },
    )
      .pipe(
        map((data: Result) => {
          const loginResult = data.result as LoginResult;
          const user = new User(
            loginResult.sigla,
            loginResult.nomec,
            true,
            undefined,
            loginResult.canal,
          );
          if (loginResult.quickMessage !== void 0) {
            this.quickMessages.next(loginResult.quickMessage);
          }
          this.setQuick();
          this.setToken(user);
          return user;
        }),
      );
  }
  /**
   * Call Auth logout and set userToken to null
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public logout(): Observable<null> {
    const user = this.userSubject.value;
    if (user) {
      this.removeLogin();

      return this.http.post<null>(
        `${this.apiUrl}/logoutAttendant`,
        { action: 'logoutAttendant', sigla: user.sigla },
      );
    }
    return of(null);
  }

  /**
   * Call available channels to login
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public channels(): Observable<Result> {
    return this.http.post<Result>(
      `${this.apiUrl}/getChannels`,
      { action: 'getChannels' },
    );
  }

  public removeLogin(): void {
    localStorage.removeItem(this.USER_KEY);
    this.userSubject.next(null);
  }
  /**
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public isAutheticated(username: string): Observable<Result> {
    return this.http.post<Result>(
      `${this.apiUrl}/getAttendant`,
      { action: 'getAttendant', sigla: username },
    )
      .pipe(
        catchError(
          this.handleError<Result>('getAttendant', { ok: true, result: {} }),
        ),
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param _operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(_operation = 'operation', result?: T): () => Observable<T> {
    return (): Observable<T> =>
      // Declaration (error: Error | any)
      // Error -> console.log(`${operation} failed: ${error.message}`);
      of(result);
  }

}
