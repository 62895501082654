import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';

import { AuthBackofficeGuard } from '../../guards/auth-backoffice.guard';
import { ChannelResult } from '../../models/channel-result';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';
import { OperatorService } from '../../services/operator.service';
import { QueueService } from '../../services/queue.service';
import { Subscription } from 'rxjs';

/***
 * Page Header
 */
@Component({
  selector: 'afe-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() public isOperator = false;

  public user: User;
  public chatUser: User;
  public channel: ChannelResult;
  public authServiceSub: Subscription[] = [];

  constructor(
    public readonly authService: AuthenticationService,
    public readonly authbackoffice: AuthBackofficeGuard,
    public readonly queueService: QueueService,
    public readonly operatorService: OperatorService,
    public readonly route: Router,
  ) { }

  /**
   * ng on init component
   */
  public ngOnInit(): void {
    this.authServiceSub.push(this.authService.user
      .pipe(
        take(1),
      )
      .subscribe(user => {
        this.user = user;

        if (this.isOperator && this.user) {
          this.authServiceSub.push(this.authService.channels()
            .pipe(
              take(1),
              tap((res) => {

                const channels = res.result as Array<ChannelResult>;
                const channel = channels.filter((ch) => ch.value === this.user.channel);

                if (channel && channel.length > 0) {
                  this.channel = channel[0];
                }
              }),
            )
            .subscribe()
          );
        }

      })
    );
  }

  public ngOnDestroy(): void {
    if (this.authServiceSub.length > 0) {
      this.authServiceSub.forEach(item => item.unsubscribe());
    }
  }

  /**
   * Action logout to Operator
   */
  public logout(): void {
    const list = document.querySelectorAll('#customers-in-service .user-item');
    if (list && Array.from(list).length > 0) {
      const msg = 'Tem certeza que deseja deslogar?\nIsto encerrar os chats ativos.';
      if (window.confirm(msg)) {
        this.callLogout();
      }
    } else {
      this.callLogout();
    }
  }

  public get uri(): string {
    return window.self.location.pathname;
  }

  public get canAccess(): boolean {
    return this.user && this.authbackoffice.superadmin.includes(this.user.sigla);
  }
  /**
   * go To Route
   */
  public async goTo(location: string): Promise<void> {
    await this.route.navigate([location]);
  }

  public callLogout(): void {
    this.authServiceSub.push(this.authService.logout()
      .subscribe(
        async () => {
          await this.route.navigate(['login']);
        },
        async () => {
          await this.route.navigate(['login']);
        })
    );
  }
}
