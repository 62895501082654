import { Directive, Input, HostBinding } from '@angular/core';

/**
 * ImagePreloadDirective
 * Diretiva para fazer preloading das imagens
 *
 * @author Edmilson Silva <ebsilva@indracompany.com>
 */
// tslint:disable: directive-selector
// tslint:disable: use-host-property-decorator
@Directive({
  selector: 'img[default]',
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src',
  },
})
export class ImagePreloadDirective {
  @Input() public src: string;
  // tslint:disable-next-line:no-reserved-keywords
  @Input() public default: string;
  @HostBinding('class') public className: string;

  public updateUrl(): void {
    this.className = 'image-default';
  }
  public load(): void {
    this.src = this.default;
    this.className = 'image-loaded';
  }
}
