import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Result } from '../models/result';
import { SSOUser } from '../models/sso-user';
import { User } from '../models/user';

interface ReqData {
  action: string;
  nome?: string;
  sigla?: string;
  ssid?: string;
  token?: string;
  subject?: string;
}

type Params = {
  user: User | string;
  token?: string;
  subject?: string;
};

/**
 * Queue Control Service
 */
@Injectable({
  providedIn: 'root',
})
export class QueueService {
  public queue = new BehaviorSubject<Array<User>>([]);
  public queueUsersId = new BehaviorSubject<Array<string>>([]);
  private readonly apiUrl = `${environment.url}`;

  constructor(
    private readonly http: HttpClient,
  ) { }

  /**
   * Start a new session in an API
   * @param params Params
   */
  public startSession(params: Params): Observable<Result> {

    const { user, token, subject } = params;

    if (user) {

      const reqData: ReqData = { action: 'startSession', token, subject };

      if (user instanceof User) {
        
        if (user.sigla && user.name) {
          reqData.nome = user.name;
          reqData.sigla = user.sigla;
        }

      } else if (typeof user === 'string') {
        reqData.ssid = user;
      }

      return this.http.post<Result>(
        `${this.apiUrl}/startSession`,
        reqData,
      ).pipe(
        catchError(
          this.handleError<Result>('startSession', { ok: false, result: {} }),
        ),
      );
    }

    return throwError({ ok: false, result: 'Não sigla e nome' });
  }

  /**
   * get queue status
   * @param sessionid string
   * @param token string | undefined
   */
  public getQueue(sessionid: string, token?: string): Observable<Result | HttpErrorResponse> {
    if (sessionid) {
      return this.http
        .post<Result>(`${this.apiUrl}/getQueue`,
          { action: 'getQueue', sessionid, token },
        );
    }
    return of({ ok: false, result: {} });
  }

  /**
   * check a session protocol
   * @param protocol string
   * @param sigla string
   */
  public checkSession(protocol: string, sigla: string): Observable<Result | HttpErrorResponse> {
    return this.http
      .post<Result>(`${this.apiUrl}/checkProtocol`,
        { action: 'checkProtocol', protocol, sigla },
      );
  }
  /**
   * check a session protocol
   * @param protocol string
   * @param sigla string
   */
  public validateProtocol(protocol: string, sigla: string): Observable<Result | HttpErrorResponse> {
    return this.http
      .post<Result>(`${this.apiUrl}/validateProtocol`,
        { action: 'validateProtocol', protocol, sigla },
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): () => Observable<T> {
    return (): Observable<T> =>
      // Declaration (error: Error | any)
      // Error -> console.info(`${operation} failed: ${error.message}`);
      of(result);
  }
}
