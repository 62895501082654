<afe-spinner [show]="loading" *ngIf="loading;else load"></afe-spinner>
<ng-template #load>
  <form [formGroup]="incidentForm" class="form" method="POST" (ngSubmit)="saveIncident()">
    <div class="form-group">
      <div class="fields boolean">
        <div class="custom-control custom-switch">
          <input type="checkbox" formControlName="incInsertSplunk" id="incInsertSplunk" class="custom-control-input" />
          <label class="custom-control-label" for="incInsertSplunk">
            Abrir Incidentes pelo Splunk
          </label>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="incidentForm.controls.incInsertSplunk.value">
      <div class="fields text">
        <label class="form-control-label" for="incCompany">
          Companhia
        </label>
        <input type="text" formControlName="incCompany" id="incCompany" class="form-control" />
      </div>
      <div class="fields text">
        <label class="form-control-label" for="incCategory">
          Categoria
        </label>
        <input type="text" formControlName="incCategory" id="incCategory" class="form-control" />
      </div>
      <div class="fields text">
        <label class="form-control-label" for="incSubcategory">
          Sub Categoria
        </label>
        <input type="text" formControlName="incSubcategory" id="incSubcategory" class="form-control" />
      </div>
      <div class="fields text">
        <label class="form-control-label" for="incShortDescription">
          Descrição Curta
        </label>
        <input type="text" formControlName="incShortDescription" id="incShortDescription" class="form-control" />
      </div>
      <div class="fields list">
        <label class="form-control-label" for="incSplunkDescription">
          Tipo de Descrição
        </label>
        <select formControlName="incSplunkDescription" id="incSplunkDescription" class="form-control">
          <option value="completo">Completa (Todas mensagens)</option>
          <option value="humano">Mensagens do Cliente e Analista</option>
          <option value="cliente">Somente mensagens do Cliente</option>
          <option value="robo">Somente mensages do Robô (RasaX)</option>
        </select>
      </div>
      <div class="fields text">
        <label class="form-control-label" for="incDescription">
          Descrição completa
        </label>
        <input type="text" formControlName="incDescription" id="incDescription" class="form-control" />
      </div>
      <div class="fields text">
        <label class="form-control-label" for="incAssignmentGroup">
          Grupo Selecionado
        </label>
        <input type="text" formControlName="incAssignmentGroup" id="incAssignmentGroup" class="form-control" />
      </div>
      <div class="fields url">
        <label class="form-control-label" for="incServiceNowUrl">
          Url do ServiceNow
        </label>
        <input type="url" placeholder="https://santander.com.br" formControlName="incServiceNowUrl"
          id="incServiceNowUrl" class="form-control" />
      </div>
    </div>
    <div id="buttons">
      <button class="btn btn-success" (click)="saveIncident()">
        Salvar
      </button>
      <button class="btn btn-secondary" (click)="resetIncident()">
        Reset
      </button>
    </div>
  </form>
</ng-template>
