<afe-header [isOperator]="true"></afe-header>
<div id="content">
  <div class="robot">
    <img src="assets/lazy_img.png" default="assets/smarthelp_warning.png" alt="Você esta na fila e espera"
      title="Você esta na fila e espera" width="175" />
    Por favor, realize o seu login.
  </div>
  <afe-alert></afe-alert>
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-group" method="post" autocomplete="off">
    <div>
      <div class="ui-float-label"
        [ngClass]="{ 'is-invalid': f.username.touched && f.username.dirty && f.username.errors }">
        <em class="std-ic_account_user"></em>
        <input class="form-control" type="text" tabindex="0" formControlName="username" id="username"
          placeholder="Usuario" />
      </div>
      <div>
        <div class="ui-float-label password"
          [ngClass]="{ 'is-invalid': f.username.touched && f.username.dirty && f.password.errors }">
          <em class="std-ic_password_typing"></em>
          <input class="form-control" tabindex="0" type="password" formControlName="password" id="password" #password
            placeholder="******" />
        </div>
      </div>
      <div>
        <div class="ui-float-label channel">
          <em class="std-ic_team_interaction"></em>
          <select class="form-control" tabindex="0" formControlName="channel" id="channel">
            <option *ngFor="let channel of channels; trackBy: track" [value]="channel.value">
              {{ channel.key }}
            </option>
          </select>
        </div>
      </div>
      <div class="bottom">
        <div class="button-area">
          <button tabindex="0" [disabled]="loading || loginForm.invalid" aria-label="Left Align" aria-hidden="true">
            Login
          </button>
          <span *ngIf="loading" class="loading"></span>
        </div>
        <div id="logo-area">
          <img src="assets/lazy_img.png" default="assets/logo_end_user_reduzido-1.png"
            alt="End-User Computing and Services" title="End-User Computing and Services" width="200" />
        </div>
      </div>
    </div>
  </form>
</div>
