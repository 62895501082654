<afe-spinner [show]="loading" *ngIf="loading;else load"></afe-spinner>
<ng-template #load>
  <form [formGroup]="channelForm" class="form" method="POST" (ngSubmit)="saveChannel()">
    <div class="form-group">
      <div class="fields array">
        <fieldset formArrayName="canais" class="fieldsets">
          <legend>Lista de canais</legend>
          <span class="btn btn-primary" (click)="add(channelForm.controls.canais)"> + </span>
          <div class="form-group"
            *ngFor="let item of channelForm.controls.canais.value.value; let i=index;trackBy: track">
            <input class="form-control" type="text" (input)="input(channelForm.controls.canais, i, in)" #in
              [value]="item" />
            <span class="btn btn-danger" *ngIf="channelForm.controls.canais.value.value.length > 1"
              (click)="remove(channelForm.controls.canais, i)"> - </span>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="form-group">
      <div class="fields array">
        <fieldset formArrayName="cores" class="fieldsets">
          <legend>Canais de seleção e cores (Login)</legend>
          <span class="btn btn-primary" (click)="add(channelForm.controls.cores, 'keyValueColor')"> + </span>
          <div class="form-group"
            *ngFor="let item of channelForm.controls.cores.value.value; let i=index;trackBy: track">
            <input class="form-control" type="text" (input)="inputKVC(channelForm.controls.cores, i, 'key', keyColor)"
              #keyColor [value]="item.key" />
            <input class="form-control" type="text"
              (input)="inputKVC(channelForm.controls.cores, i, 'value', valueColor)" #valueColor [value]="item.value" />
            <input class="form-control input-color-picker" type="color" (input)="item.color=$event.target.value"
              [value]="item.color" />
            <span class="btn btn-danger" *ngIf="channelForm.controls.cores.value.value.length > 1"
              (click)="remove(channelForm.controls.cores, i)"> - </span>
          </div>
        </fieldset>
      </div>
    </div>

    <div id="buttons">
      <button class="btn btn-success" (click)="saveChannel()">
        Salvar
      </button>
      <button class="btn btn-secondary" (click)="resetChannel()">
        Reset
      </button>
    </div>
  </form>
</ng-template>
