// Query String parameters
/**
 * Uri Query Params
 * params matched
 */
export class QueryParams {
  // session id
  public ssid?: string;
  // user penumper
  public senderid?: string;
  // user name
  public name?: string;
  // token to identify the chat channel
  public token?: string;
  // Subject to start chatbot
  public subject?: string;
}
