<div *ngIf="!disabled; else notFound">
  <afe-header></afe-header>

  <afe-online [status]="online"></afe-online>

  <div class="welcome">
    <div *ngIf="!message && inSession && sessionId" #activeSessionMessage>
      <div class="robot">
        <img src="assets/lazy_img.png" default="assets/smarthelp_warning.png" alt="Você esta na fila e espera"
          title="Você esta na fila e espera" width="200" height="138" />
      </div>
      <div class="queue-message-active">
        <p class="message">
          Já existe uma sessão em andamento para sua sigla, favor continue por lá seu atendimento
        </p>
      </div>
    </div>

    <div *ngIf="!message && inSession && sessionId" class="access-message">
      <p class="message" *ngIf="changeMsgProtocol(); else protocolMsg">
        Favor, insira abaixo o número do seu documento que começa com <strong>{{ protocol }}</strong>.
      </p>
      <ng-template #protocolMsg>
        <p class="message">
          Favor, insira abaixo o número do protocolo recebido no seu atendimento.
        </p>
      </ng-template>
      <form [formGroup]="protocolForm" (ngSubmit)="checkSession()">
        <span *ngIf="error && f.protocol.dirty" class="error">
          Este protocolo não é válido para seu atendimento.
        </span>
        <div>
          <input [placeholder]="changeMsgProtocol()?'Documento':'Protocolo'" formControlName="protocol" />
          <input class="btn" (click)="checkSession()" value="Enviar" />
        </div>
      </form>
    </div>
    <div *ngIf="!message && !inSession" #queueMessage>
      <div class="robot">
        <img src="assets/lazy_img.png" default="assets/smarthelp_warning.png" alt="Você esta na fila e espera"
          title="Você esta na fila e espera" width="150" height="104" />
        <p>Por favor, aguarde. Em breve atenderemos.</p>
      </div>
      <div class="queue-message">
        <p>
          Você está na posição <strong class="red">{{ position }}</strong> na fila de espera.
          <span *ngIf="waitTime > 0">O tempo de espera é de aproximadamente <strong class="red">{{ waitTime }}</strong>
            min.</span>
        </p>
        <p class="protocol" *ngIf="protocol && protocol.substr(-3) !== '***'; else documento">
          Guarde o número do seu protocolo:
          <strong class="red">{{protocol}}</strong>. Caso necessite recuperar o atendimento este será necessário.
        </p>
        <ng-template #documento>
          <p class="protocol">
            Seu protocolo é seu documento que começa com o <strong class="red">{{protocol}}</strong>.
            Caso necessite recuperar o atendimento este será necessário
          </p>
        </ng-template>
      </div>
    </div>
    <div *ngIf="message && !inSession || (!message && inSession && !sessionId)" #abandonmentMessage>
      <div class="robot">
        <img src="assets/lazy_img.png" default="assets/smart_help_sad.png" alt="Você me abandonou"
          title="Você me abandonou" width="200" height="131" />
      </div>
      <div class="queue-message-abandonment">
        <p class="message">
          Você me abandonou, caso ainda precise de mim
          <a [href]="'?senderid=' + sigla + (token? '&token=' + token: '')" title="Recomeçar o atendimento">
            <strong>clique aqui</strong>
          </a>
        </p>
      </div>
    </div>
  </div>
  <div class="easyAccess" *ngIf="!message && !inSession">
    <span class="red">
      Não quer aguardar?
    </span>
    <span class="red" *ngIf="!isMobile() && !message && !inSession">Abaixo temos algumas opções de requisições e
      incidentes, você pode abrir diretamente para as
      equipes
      solucionadoras.</span>
    <div *ngIf="!isMobile() && !message && !inSession">
      <button aria-label="Justify Align" aria-hidden="true"
        (click)="closeSession($event.target.innerText, 'https://santander.service-now.com/brsp?id=itsm_cat_item&sys_id=f649c694db78dc107f668c994b96192f&sysparm_category=aa26066edb1548907f668c994b961939')"
        title="Manutenção em estações, perifericos e acessorios">
        Manutenção Hardware
      </button>
      <button aria-label="Justify Align" aria-hidden="true"
        (click)="closeSession($event.target.innerText, 'https://santander.service-now.com/brsp?id=itsm_cat_item&sys_id=ddfff7bd1bb0541020044002cd4bcbbd&sysparm_category=cf4418c5dbd508507f668c994b961961')"
        title="Incidentes ou manutenção do Telefone">
        Telefonia
      </button>
      <button aria-label="Justify Align" aria-hidden="true"
        (click)="closeSession($event.target.innerText, 'https://santander.service-now.com/brsp?id=itsm_cat_item&sys_id=a31aebbd1bfc141020044002cd4bcbf9&sysparm_category=a67c073d1bf8141020044002cd4bcb2c')"
        title="Incidentes relacionados a Comunicação e Redes de Dados">
        Comunicação e Redes
      </button>
      <button aria-label="Justify Align" aria-hidden="true"
        (click)="closeSession($event.target.innerText, 'https://santander.service-now.com/brsp?id=itsm_cat_item&sys_id=cda9ee6e1ba3801020044002cd4bcb07&sysparm_category=2833bdea1b6b401020044002cd4bcb3b')"
        title="Incidentes em Impressora">
        Incidentes Impressora
      </button>
      <button aria-label="Justify Align" aria-hidden="true"
        (click)="closeSession($event.target.innerText, 'https://santander.service-now.com/brsp?id=itsm_cat_item&sys_id=195f3009dbb91c109706e7640596190e&sysparm_category=null')"
        title="Solicitação de Notebook">
        Solicitar Notebook
      </button>
    </div>
    <div *ngIf="!isMobile() && !message && !inSession">
      <button aria-label="Justify Align" aria-hidden="true"
        (click)="closeSession($event.target.innerText, 'https://santander.service-now.com/brsp?id=itsm_cat_item&sys_id=896eefac1be3081420044002cd4bcbbe&sysparm_category=null')"
        title="Solicitação de Software">
        Solicitar Software
      </button>
      <button aria-label="Justify Align" aria-hidden="true"
        (click)="closeSession($event.target.innerText, 'https://santander.service-now.com/brsp?id=itsm_cat_item&sys_id=5cc877f51b70541020044002cd4bcb61&sysparm_category=1f5dc77d1bf8141020044002cd4bcb6a')"
        title="Contestação de chamados de Acessos (Segurança da Informação)">
        Contestar acessos
      </button>
      <button aria-label="Justify Align" aria-hidden="true"
        (click)="closeSession($event.target.innerText, 'https://santander.service-now.com/brsp?id=itsm_cat_item&sys_id=b7e88a66db5548907f668c994b9619b5')"
        title="Devolução de Equipamento">
        Devolver Equipamento
      </button>
      <button aria-label="Justify Align" aria-hidden="true"
        (click)="closeSession($event.target.innerText, 'https://santander.service-now.com/brsp?id=itsm_cat_item&sys_id=068b37401b6908d020044002cd4bcbfd&sysparm_category=null')"
        title="Suporte aos Produtos SAS/QLIK/Big Data">
        SAS/QLIK/ Big Data
      </button>
      <button aria-label="Justify Align" aria-hidden="true"
        (click)="closeSession($event.target.innerText, 'https://santander.service-now.com/brsp?id=itsm_cat_item&sys_id=8a446517db14eb008c6c7cde3b96197c&sysparm_category=77f26029db6dc4147f668c994b96197e')"
        title="Outros problemas">
        Outros problemas
      </button>
    </div>
  </div>
  <div class="closex" *ngIf="!message && !inSession">
    <a href="javascript:void(0)" title="Abandonar a fila" (click)="closeSession($event.target.innerText, '#')">
      Sair da fila
      <em class="std-ic_circle_clouse"></em>
    </a>
  </div>
  <div class="base-qr-code">
    <div class="qr-code" *ngIf="!isMobile() && !message && !inSession" (click)="qrcode()">
      <p class="qr-code-text">
        Caso necessite, continue com o Smart Help no seu smartphone.
        <strong>Clique e escaneie o QR Code.</strong>
      </p>
      <div>
        <em class="std-ic_qrcode lg-icon" title="Qr Code"></em>
      </div>
    </div>
  </div>
</div>
<div id="background-modal" *ngIf="qrcodeShow" (click)="closeQrcode()"></div>
<div id="modal" *ngIf="qrcodeShow">
  <em class="modal-close" (click)="closeQrcode()">x</em>
  <div id="modal-content">
    <p>
      <strong>
        Escaneie o QR Code abaixo para continuar com o Smart Help no seu smartphone.
      </strong>
    </p>
    <qrcode [qrdata]="uri" [width]="200">
    </qrcode>
  </div>
</div>

<ng-template #notFound>
  <afe-error404></afe-error404>
</ng-template>