// User Default data
/**
 * User
 * Attendant or Costumer
 * Or System
 */
export class User {
  constructor(
    // User penumper
    public readonly sigla: string,
    // User name
    public readonly name: string,
    // If the user is attendant
    public readonly isAttendant: boolean = false,
    // User mail
    public readonly email?: string,
    // User channel
    public readonly channel?: string,
    // User phone
    public phone?: string,
  ) {
    if (!email) {
      let domain = 'santander.com.br';
      if (this.sigla && this.sigla.startsWith('X')) {
        domain = 'prservicos.com.br';
      }
      this.email = `${sigla}@${domain}`;
    }
  }
}
