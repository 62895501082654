import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError, flatMap, take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ChatMessage } from '../models/chat-message';
import { ChatResult } from '../models/chat-result';
import { QueryParams } from '../models/query-params';
import { Result } from '../models/result';
import { User } from '../models/user';

/**
 * ChatService
 */
@Injectable({
  providedIn: 'root',
})
export class ChatService {

  public chatMessage = new BehaviorSubject<Array<ChatMessage>>([]);
  public chatAttendant = new BehaviorSubject<User>(undefined);
  public userid: string;
  private readonly apiUrl = `${environment.url}`;

  constructor(
    public readonly aroute: ActivatedRoute,
    public readonly http: HttpClient,
  ) { }

  /**
   * Send new Message
   */
  public sendMessage(sessionid: string, user: User, text: string, token?: string): Observable<Result> {
    return this.http
      .post<Result>(`${this.apiUrl}/sendMessage`,
        { action: 'sendMessage', sessionid, sigla: user.sigla, nome: user.name, isoperator: false, isbot: false, text, token },
      )
      .pipe(
        catchError(
          this.handleError<Result>('sendMessage', { ok: false, result: {} }),
        ),
      );
  }
  /**
   * Send survey to index
   */
  public sendSurvey(rating: number, sessionid: string, user?: User): Observable<Result> {
    return this.http
      .post<Result>(`${this.apiUrl}/sendSurvey`,
        { action: 'sendSurvey', sessionid, sigla: user.sigla, nome: user.name, rating },
      )
      .pipe(
        catchError(
          this.handleError<Result>('sendSurvey', { ok: false, result: {} }),
        ),
      );
  }

  /**
   * Send new Message
   */
  public getMessages(sessionid: string, token?: string): Observable<Result> {
    return this.http
      .post<Result>(`${this.apiUrl}/getMessage`,
        { action: 'getMessage', sessionid, token },
      )
      .pipe(
        flatMap((res: Result) => {
          if (res.ok) {
            const result = res.result as ChatResult;
            if (result.sigla) {
              this.userid = result.sigla;
            }
          }
          return of(res);
        }),
      );
  }

  public get uri(): string {
    let uri = `?senderid=${this.userid}`;
    this.aroute.queryParams.pipe(
      take(1),
    )
      .subscribe((params: QueryParams) => {
        if (params.token) {
          uri += `&token=${params.token}`;
        }
      });

    return uri;
  }

  public handleMessage(msg: string): string {
    return this.emphasisParse(
      this.emphasisParse(
        msg
          .replace(/&/g, '&amp;')
          .replace(/<button(?:.[^>]*)\>(.[^\<\>]*)<\/button\>/g, '<button>$1</button>')
          // change tags
          .replace(/</g, '&lt;')
          // change tags
          .replace(/>/g, '&gt;')
          .replace(/&lt;(\/)?button&gt;/g, '<$1button>')
          // lists
          .replace(/(\n|\s+)(\*\s([^\n]+)\n?)+/, (match: string) => {
            const list = match.replace(/\*\s([^\n]+)\n?/g,
              (_mt: string, listItem: string) => `<li>${listItem}</li>`);
            return `<ul>${list}</ul>`;
          })
          // hyperlink typing [text](link)
          .replace(/\[([^\[\]]+)\]\(([^)]+)\)/g, (_m, text: string, link: string) => {
            let anchor = link;
            if (!/^https?\:\/\//.test(anchor)) {
              anchor = `https://${link}`;
            }
            return `<a href="${anchor}" target="_blank" rel="nofollow">${text}</a>`;
          })
          .replace(/^(https?)/i, ' $1')
          // hyperlink typing http://website.com ...
          .replace(/(?:\s)+(https?\:\/\/)([^\/][^\s]+)/g,
            (m, protocol: string, text: string) => {
              const link = `${protocol}${text}`;
              const result = `<a href="${link}" target="_blank" rel="nofollow">${link}</a>`;
              return m.replace(link, result);
            }),
      ),
    )
      // change break line to br tag
      .replace(/(\n\r?|\\n|&lt;\/?br\s*\/?&gt;)/g, '<br/>')
      // change multiples spaces to space tag
      .replace(/\s{2}/g, '&nbsp;&nbsp;')
      .replace(/\t+/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
      .replace(
        '<strong>Smart Help</strong>',
        `<a href="${this.uri}" target="_self" title="Smart Help">
<strong>Smart Help</strong>
</a>`,
      );
  }

  /**
   * replace by <strong> or <em> to emphasize the text
   * @param text string
   */
  public emphasisParse(text: string): string {
    return text.replace(/\s(__|\*\*|_|\*)(.*?)\1/g,
      (_m, item: string, value: string) => {
        const map = {
          '**': 'strong',
          __: 'strong',
          _: 'em',
          '*': 'em',
        };
        const tag = map[item];
        return ` <${tag}>${value}</${tag}>`;
      });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): () => Observable<T> {
    return (): Observable<T> =>
      // Declaration (error: Error | any)
      // Error -> console.log(`${operation} failed: ${error.message}`);
      of(result);
  }
}
