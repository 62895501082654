import { Component } from '@angular/core';

/**
 * App Component -
 */
@Component({
  selector: 'afe-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public title = 'smarthelp';
}
