import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AlertService } from '../services/alert.service';

export interface KeyValue {
  key: string;
  value: string;
}
export interface TitleText {
  title: string;
  text: string;
}
export interface KeyValueColor {
  key: string;
  value: string;
  color: string;
}

export interface Indexable {
  [key: string]: string;
}

export type TypeBase = string | KeyValue | TitleText | KeyValueColor;
export type TypeField = 'default' | 'keyValue' | 'keyValueColor' | 'titleText';
export type TypeInputItem = 'key' | 'value' | 'title' | 'text';

/**
 * Form Base Ref
 */
export abstract class FormBase {
  /**
   * Remove item from the list
   * @param elem FormControl
   * @param item number
   */
  public remove(elem: FormControl | AbstractControl, item: number): void {
    const formArr = elem.value as FormArray;
    formArr.removeAt(item);
  }
  /**
   * Add new items to the list
   * @param elem FormControl
   * @param fieldType 'default' | 'keyValue' | 'keyValueColor' | 'titleText'
   */
  public add(elem: FormControl | AbstractControl, fieldType: TypeField = 'default'): void {
    const formArr = elem.value as FormArray;
    let base: TypeBase = '';
    if (fieldType !== 'default') {
      switch (fieldType) {
        case 'keyValueColor':
          base = { key: '', value: '', color: '#cccccc' };
          break;
        case 'titleText':
          base = { title: 'Título', text: 'Mensagem' };
          break;
        case 'keyValue':
        default:
          base = { key: '', value: '' };
      }
    }
    formArr.push(new FormControl(base));
  }
  /**
   * input data of list
   * @param elem FormControl
   * @param item number
   * @param inElem HTMLInputElement
   */
  public input(elem: FormControl | AbstractControl, item: number, inElem: HTMLInputElement): void {
    const formArr = elem.value as FormArray;
    formArr.controls[item].setValue(inElem.value);
    inElem.focus();
  }
  /**
   * input data of list
   * @param elem FormControl
   * @param item number
   * @param field 'key' | 'value' | 'title' | 'text'
   * @param inElem HTMLInputElement
   */
  public inputKVC(
    elem: FormControl | AbstractControl, item: number, field: TypeInputItem, inElem: HTMLInputElement,
  ): void {
    const formArr = elem.value as FormArray;
    const value = formArr.controls[item].value as Indexable;
    value[field] = inElem.value;
    inElem.focus();
    formArr.controls[item].setValue(value);
  }
  /**
   * track -> by index
   * @param index number
   */
  public track(index: number): number {
    return index;
  }

  /**
   * timeout to Clear Msgs
   * @param alert AlertService
   * @param sub Subscrition
   */
  public timeoutClearMsg(alert: AlertService, sub?: Subscription): NodeJS.Timer {
    const messageTimeout = 5000;
    const timedout = setTimeout(() => {
      alert.clear();
    }, messageTimeout);
    if (sub) {
      sub.unsubscribe();
    }
    return timedout;
  }
}
