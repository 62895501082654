import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Indexable } from '../models/form-base';
import { Result } from '../models/result';
import { User } from '../models/user';

type SettingsType = number | boolean | Array<string> | undefined | Indexable | Array<Indexable>;
export interface Settings {
  [key: string]: string | SettingsType;
}
export interface Setting {
  title: string;
  settings: Settings;
}

/**
 * BackOffice service data, settings
 */
@Injectable({
  providedIn: 'root',
})
export class BackofficeService {

  private readonly apiUrl = environment.url;
  private readonly TOKEN = environment.token;

  constructor(
    public readonly http: HttpClient,
  ) { }

  public getGeneral(user: User): Observable<Settings> {
    const token = this.getToken(user);
    return this.http
      .post<Result>(`${this.apiUrl}/getGeneralSettings`,
        { action: 'getGeneralSettings', token },
      )
      .pipe(
        map((res) => res.result as Settings),
      );
  }

  public setGeneral(user: User, data: Settings): Observable<string> {
    const token = this.getToken(user);
    return this.http
      .post<Result>(`${this.apiUrl}/setGeneralSettings`,
        { action: 'setGeneralSettings', ...data, token },
      )
      .pipe(
        map((res) => res.result as string),
      );
  }
  public getChannel(user: User): Observable<Settings> {
    const token = this.getToken(user);
    return this.http
      .post<Result>(`${this.apiUrl}/getChannelSettings`,
        { action: 'getChannelSettings', token },
      )
      .pipe(
        map((res) => res.result as Settings),
      );
  }

  public setChannel(user: User, data: Settings): Observable<string> {
    const token = this.getToken(user);
    return this.http
      .post<Result>(`${this.apiUrl}/setChannelSettings`,
        { action: 'setChannelSettings', ...data, token },
      )
      .pipe(
        map((res) => res.result as string),
      );
  }
  public getIncident(user: User): Observable<Settings> {
    const token = this.getToken(user);
    return this.http
      .post<Result>(`${this.apiUrl}/getIncidentSettings`,
        { action: 'getIncidentSettings', token },
      )
      .pipe(
        map((res) => res.result as Settings),
      );
  }

  public setIncident(user: User, data: Settings): Observable<string> {
    const token = this.getToken(user);
    return this.http
      .post<Result>(`${this.apiUrl}/setIncidentSettings`,
        { action: 'setIncidentSettings', token, ...data },
      )
      .pipe(
        map((res) => res.result as string),
      );
  }
  public getQuickMsg(user: User): Observable<Array<Settings>> {
    const token = this.getToken(user);
    return this.http
      .post<Result>(`${this.apiUrl}/getQuickMsgSettings`,
        { action: 'getQuickMsgSettings', token },
      )
      .pipe(
        map((res) => res.result as Array<Settings>),
      );
  }

  public setQuickMsg(user: User, data: Array<Settings>): Observable<string> {
    const token = this.getToken(user);
    return this.http
      .post<Result>(`${this.apiUrl}/setQuickMsgSettings`,
        { action: 'setQuickMsgSettings', token, data },
      )
      .pipe(
        map((res) => res.result as string),
      );
  }

  /**
   * getAdmins
   */
  public getAdmins(user: User): Observable<Array<string>> {
    const token = this.getToken(user);
    return this.http.post<Result>(
      `${this.apiUrl}/getAdmins`,
      { action: 'getAdmins', token },
    )
      .pipe(
        map((res) => res.result as Array<string>),
      );
  }

  public getToken(user: User): string {
    return btoa(`${user ? user.sigla : void 0}:${this.TOKEN}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param _operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(_operation = 'operation', result?: T): () => Observable<T> {
    return (): Observable<T> =>
      // Declaration (error: Error | any)
      // Error -> console.log(`${operation} failed: ${error.message}`);
      of(result);
  }
}
