import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

/**
 * Check online or offline
 */
@Injectable({
  providedIn: 'root',
})
export class OnlineService {

  /**
   * Check from Event if navigator is online or offline
   */
  public checkOnline(): Observable<boolean> {
    return merge(
      fromEvent(window, 'offline')
        .pipe(mapTo(false)),
      fromEvent(window, 'online')
        .pipe(mapTo(true)),
      of(navigator.onLine),
    );
  }
}
