import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AlertMessage } from '../models/alert-message';

/**
 * AlertService
 */
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private readonly subject = new Subject<AlertMessage>();

  /**
   * Get all alert messages
   */
  public getAlert(): Observable<AlertMessage> {
    return this.subject.asObservable();
  }

  /**
   * Create a new Success Message
   * @param message string
   */
  public success(message: string): void {
    this.subject.next({ severity: 'success', detail: message });
  }

  /**
   * Create a new Infomation Message
   * @param message string
   */
  public info(message: string): void {
    this.subject.next({ severity: 'info', detail: message });
  }

  /**
   * Create a new Warning Message
   * @param message string
   */
  public warn(message: string): void {
    this.subject.next({ severity: 'warn', detail: message });
  }

  /**
   * Create a new Error Message
   * @param message string
   */
  public error(message: string): void {
    this.subject.next({ severity: 'error', detail: message });
  }

  /**
   * Remove all Messages
   */
  public clear(): void {
    this.subject.next();
  }
}
