import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { FormBase } from '../../models/form-base';
import { User } from '../../models/user';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BackofficeService, Settings } from '../../services/backoffice.service';

/**
 * General settings form
 */
@Component({
  selector: 'afe-form-general',
  templateUrl: './form-general.component.html',
  styleUrls: ['./form-general.component.scss'],
})
export class FormGeneralComponent extends FormBase implements OnInit, OnDestroy {

  public generalForm: FormGroup;
  public generalReset: Settings;
  public generalSub: Subscription;
  public setGeneral: Subscription[] = [];
  public loading = true;
  private timeout: NodeJS.Timer = null;
  private readonly user: User;

  constructor(
    public readonly formBuilder: FormBuilder,
    public readonly backOfficeService: BackofficeService,
    public readonly alertService: AlertService,
    public readonly authService: AuthenticationService,
  ) {
    super();
    this.user = this.authService.userValue;
  }
  /**
   * when the view starts
   */
  public ngOnInit(): void {
    this.generalSub = this.backOfficeService.getGeneral(this.user)
      .pipe(
        take(1),
      )
      .subscribe((configs: Settings) => {
        const channels = this.formBuilder.array(configs.channels as Array<string>);
        const formData: { [key: string]: FormControl } = {};
        Object.keys(configs)
          .forEach((key) => {
            let value = this.formBuilder.control(configs[key], Validators.required);
            if (key === 'channels') {
              value = this.formBuilder.control(channels, Validators.required);
            }
            formData[key] = value;
          });
        this.generalForm = this.formBuilder.group(formData);
        this.generalReset = this.generalForm.value as Settings;
        this.generalReset.channels = configs.channels as Array<string>;
        this.loading = false;
      }, () => { this.loading = false; });
  }
  /**
   * when the view is destroyed
   */
  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
    if (this.generalSub) {
      this.generalSub.unsubscribe();
    }

    if (this.setGeneral.length > 0) {
      this.setGeneral.forEach(item => item.unsubscribe());
    }
  }

  /**
   * Save General settings data
   */
  public saveGeneral(): void {
    this.alertService.clear();
    this.loading = true;

    const channelList = (this.generalForm.controls.channels.value as FormArray)
      .value as Array<string>;

    const data: Settings = {
      ...this.generalForm.value,
      ...{ channels: channelList },
    };

    this.setGeneral.push(this.backOfficeService.setGeneral(this.user, data)
      .pipe(
        take(1),
      )
      .subscribe(
        () => {
          this.generalReset = this.generalForm.value as Settings;
          const channels = this.generalForm.controls.channels.value as FormArray;
          this.generalReset.channels = channels.value as Array<string>;
          this.alertService.success('Configurações gerais salva');
          this.timeout = this.timeoutClearMsg(this.alertService);
          this.loading = false;
        },
        () => {
          this.alertService.error('Erro ao salvar configurações gerais');
          this.loading = false;
          this.timeout = this.timeoutClearMsg(this.alertService);
        }
      ));
  }

  /**
   * reset General settings
   */
  public resetGeneral(): void {
    this.generalForm.setValue(this.generalReset);
    this.generalForm.controls.channels.setValue(
      this.formBuilder.array(this.generalReset.channels as Array<string>),
    );
  }

}
