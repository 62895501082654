import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { FormBase } from '../../models/form-base';
import { User } from '../../models/user';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BackofficeService, Settings } from '../../services/backoffice.service';

/**
 * Incident settings form
 */
@Component({
  selector: 'afe-form-incident',
  templateUrl: './form-incident.component.html',
  styleUrls: ['./form-incident.component.scss'],
})
export class FormIncidentComponent extends FormBase implements OnInit, OnDestroy {

  public incidentForm: FormGroup;
  public incidentReset: Settings;
  public incidentSub: Subscription;
  public setIncident: Subscription[] = [];
  public loading = true;
  private timeout: NodeJS.Timer = null;
  private readonly user: User;

  constructor(
    public readonly formBuilder: FormBuilder,
    public readonly backOfficeService: BackofficeService,
    public readonly alertService: AlertService,
    public readonly authService: AuthenticationService,
  ) {
    super();
    this.user = this.authService.userValue;
  }
  /**
   * when the view starts
   */
  public ngOnInit(): void {
    this.incidentSub = this.backOfficeService.getIncident(this.user)
      .pipe(
        take(1),
      )
      .subscribe((configs) => {
        const formData: { [key: string]: FormControl } = {};
        Object.keys(configs)
          .forEach((key) => {
            formData[key] = this.formBuilder.control(configs[key], Validators.required);
          });
        this.incidentForm = this.formBuilder.group(formData);
        this.incidentReset = this.incidentForm.value as Settings;
        this.loading = false;
      }, () => { this.loading = false; });
  }
  /**
   * when the view is destroyed
   */
  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
    if (this.incidentSub) {
      this.incidentSub.unsubscribe();
    }
    if (this.setIncident.length > 0) {
      this.setIncident.forEach(item => item.unsubscribe());
    }
  }

  /**
   * Save Incident settings data
   */
  public saveIncident(): void {

    this.alertService.clear();
    this.loading = true;

    const data: Settings = {
      ...this.incidentForm.value,
    };

    this.setIncident.push(this.backOfficeService.setIncident(this.user, data)
      .pipe(
        take(1),
      )
      .subscribe(
        () => {
          this.incidentReset = this.incidentForm.value as Settings;
          this.alertService.success('Configuração de incidente salva');
          this.loading = false;
          this.timeout = this.timeoutClearMsg(this.alertService);
        },
        () => {
          this.alertService.error('Erro ao salvar configuração de incidente');
          this.loading = false;
          this.timeout = this.timeoutClearMsg(this.alertService);
        }
      ));
  }

  /**
   * reset Incident settings
   */
  public resetIncident(): void {
    this.incidentForm.setValue(this.incidentReset);
  }

}
