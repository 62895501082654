<afe-header [isOperator]="true"></afe-header>
<afe-online [status]="online"></afe-online>
<div id="settings">
  <afe-alert></afe-alert>
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="tab" *ngFor="let tab of tabs; trackBy: track">
      <a ngbNavLink (click)="alertService.clear()">
        {{ tab.title }}
      </a>
      <ng-template ngbNavContent>
        <div [ngSwitch]="tab.text">
          <afe-form-general *ngSwitchCase="'general-form'">
          </afe-form-general>
          <afe-form-channel *ngSwitchCase="'channel-form'">
          </afe-form-channel>
          <afe-form-incident *ngSwitchCase="'incident-form'">
          </afe-form-incident>
          <afe-form-quick-message *ngSwitchCase="'quick-message-form'">
          </afe-form-quick-message>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>

</div>
