import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TitleText } from '../../models/form-base';
import { User } from '../../models/user';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BackofficeService } from '../../services/backoffice.service';
import { OnlineService } from '../../services/online.service';
import { OperatorService } from '../../services/operator.service';

/**
 * Page backoffice
 */
@Component({
  selector: 'afe-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss'],
})
export class BackofficeComponent implements OnInit, OnDestroy {

  public online: boolean = true;
  public loading = false;
  public countdownEndSub: Subscription;
  public onlineSub: Subscription;

  public tabs: Array<TitleText> = [
    { title: 'Geral', text: 'general-form' },
    { title: 'Canais', text: 'channel-form' },
    { title: 'Incidente', text: 'incident-form' },
    { title: 'Mensagens Rápidas', text: 'quick-message-form' },
  ];

  public active = this.tabs[0];

  private readonly timer = 10;
  private readonly user: User;

  constructor(
    public readonly onlineService: OnlineService,
    public readonly formBuilder: FormBuilder,
    public readonly backOfficeService: BackofficeService,
    public readonly alertService: AlertService,
    public readonly operatorService: OperatorService,
    public readonly authService: AuthenticationService,
    public readonly route: Router,
  ) {
    this.user = this.authService.userValue;
    this.operatorService.startCountdown(this.timer);
  }

  public ngOnInit(): void {
    this.countdownEndSub = this.operatorService.countdownEndSource
      .pipe(
        tap(async () => {
          this.operatorService.process(this.online);
          try {
            const res = await this.authService
              .isAutheticated(this.user.sigla)
              .toPromise();
            if (!res.ok) {
              this.authService.removeLogin();
              await this.route.navigate(['login']);
            }
          } catch (e) {
            // nothing to do
          }
        }),
      )
      .subscribe();
    this.onlineSub = this.onlineService.checkOnline()
      .pipe(
        tap((online) => {
          this.online = online;
          if (this.online) {
            this.operatorService.countdownEndSource.next();
          }
        }),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    if (this.onlineSub) {
      this.onlineSub.unsubscribe();
    }
    if (this.countdownEndSub) {
      this.countdownEndSub.unsubscribe();
    }
  }

  /**
   * track -> by index
   * @param index number
   */
  public track(index: number): number {
    return index;
  }

}
