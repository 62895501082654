import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ChatMessage } from '../../models/chat-message';
import { ChatService } from '../../services/chat.service';

/**
 * Chat message
 * Messages of Chat
 */
@Component({
  selector: 'afe-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent {
  @Input() public message: ChatMessage;

  constructor(
    public readonly sanatizer: DomSanitizer,
    public readonly chatService: ChatService,
    public readonly elementRef: ElementRef,
  ) { }

  public get text(): SafeHtml {
    return this.sanatizer.bypassSecurityTrustHtml(
      this.chatService.handleMessage(this.message.text),
    );
  }

  // tslint:disable-next-line:use-life-cycle-interface
  public ngAfterViewChecked(): void {
    const elemref = this.elementRef.nativeElement as HTMLElement;
    const btns: Array<HTMLElement> = Array.from(elemref.querySelectorAll('button'));
    if (btns.length > 0) {
      btns.forEach((btn) => {
        btn.removeEventListener('click', () => { });
        btn.addEventListener('click', (event) => {
          event.stopImmediatePropagation();
          event.preventDefault();
          const elem = event.target as HTMLButtonElement;
          const send: HTMLButtonElement = document.querySelector('#form-chat>#sendButton');
          if (elem && send) {
            const textarea: HTMLTextAreaElement = document.querySelector('#sendMessage');
            if (!textarea.disabled) {
              textarea.value = elem.innerText;
              send.click();
            }
          }
        });
      });
    }
  }
}
