import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Result } from '../models/result';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NpsService {
  private readonly apiUrl = `${environment.url_nps}`;

  constructor(public http: HttpClient,

    ) {
  }

  public startSession(sessionId: string) {
    const body = {
      action: "startSession",
      backend: "backend_nps",
      sessionId: sessionId
    }
    return this.http.post<Result>(this.apiUrl, body)
  }

  public sendMessage(sessionId: string, text: string) {
    const body = { action: "sendMessage",
    backend: "backend_nps",
    sessionId: sessionId,
    text: text }
    return this.http.post<Result>(this.apiUrl, body)
  }


}
