import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

/**
 * ClickOutSideDirective
 * Diretiva para clique fora do objeto principal
 *
 * @author Edmilson Silva <ebsilva@indracompany.com>
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[clickOutside]',
})
export class ClickOutSideDirective {
  // tslint:disable-next-line:prefer-output-readonly
  @Output('clickOutside') public clickOutside: EventEmitter<null> = new EventEmitter();
  constructor(private readonly _elementRef: ElementRef) { }

  /**
   * onMouseEnter action
   * @param targetElement HTMLElement
   */
  // tslint:disable-next-line: no-unsafe-any
  @HostListener('document:click', ['$event.target']) public onMouseEnter(targetElement: HTMLElement): void {
    const elem = this._elementRef.nativeElement as HTMLElement;
    const clickedInside = elem.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(null);
    }
  }
}
