<div class="chat-message">
  <div class="initials" [title]="message.sender.name">
    <img *ngIf="message.sender.isAttendant; else userImg" width="70" height="70" src="assets/robot.png"
      [alt]="message.sender.name"
      default="assets/robot.png" />
    <ng-template #userImg>
      <img width="70" height="70" src="assets/user.png" [alt]="message.sender.name"
        default="assets/user.png" />
    </ng-template>
  </div>
  <div class="message-area">
    <div class="message-sender" *ngIf="!message.sender.sigla.includes('T999999')">
      {{message.sender.isAttendant?'Analista: ':''}}{{message.sender.name.split(' ')[0]}}
    </div>
    <div class="message">
      <div class="message-text">
        <p class="text" [innerHTML]="text"></p>
      </div>
    </div>
    <span class="message-data">
      <div class="message-confirmation">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></svg>
        </span>
      </div>
      <span class="date">{{message.date | date:'HH:mm'}}</span>
    </span>
  </div>
</div>
