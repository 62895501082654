import { User } from './user';

/**
 * Message
 * A chat message
 */
export class ChatMessage {
  constructor(
    public readonly sender: User,
    public readonly text: string,
    public readonly date = new Date(),
  ) { }
}
