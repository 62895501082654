import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';

import { ChannelResult } from '../../models/channel-result';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';

interface ErrorResult {
  ok: boolean;
  code: number;
  result: string;
}
/**
 * Login Page Component
 * Componente para tela de login
 * Pagina de Login
 * @author Edmilson Silva <ebsilva@indracompany.com>
 */
@Component({
  preserveWhitespaces: false,
  selector: 'afe-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public loading = false;
  public channels: Array<ChannelResult> = [
    { key: 'Smart', value: '#SMART' },
  ];

  constructor(
    public readonly router: Router,
    public readonly alertService: AlertService,
    public readonly authenticationService: AuthenticationService,
    private readonly formBuilder: FormBuilder,
  ) {
    // if logged
    if (this.authenticationService.userValue) {
      // tslint:disable-next-line: no-floating-promises
      this.router.navigate(['/admin']);
    }
  }

  /**
   * ngOnInit
   * Ações de carregamento
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public ngOnInit(): void {
    let channel = this.authenticationService.lastChannel.value;
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      channel: [channel, Validators.required],
    });
    this.authenticationService.channels()
      .pipe(
        take(1),
        tap((res) => {
          this.channels = res.result as Array<ChannelResult>;
          const channels = this.channels.map((ch) => ch.value);
          if (!channels.includes(channel)) {
            channel = '#SMART';
            this.f.channel.setValue(channel);
          }
        }),
      )
      .subscribe();
  }

  /**
   * f
   * Getter to Form Fields
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  /**
   * track -> by index
   * @param index number
   */
  public track(index: number): number {
    return index;
  }

  /**
   * OnSubmit
   * Ação ao enviar o formulário
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public onSubmit(): void {
    this.alertService.clear();
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService.login(
      this.f.username.value as string,
      this.f.password.value as string,
      this.f.channel.value as string,
    )
      .subscribe(
        async () => {
          this.loading = false;
          if (window.location.href.endsWith('login')) {
            window.location.href = '/admin';
          }
          await this.router.navigate(['/admin']);
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          let errorMsg = 'Usuário ou Senha inválidos';
          const forbidden = 403;
          if (err.status === forbidden) {
            const error = err.error as ErrorResult;
            errorMsg = error.result;
          }
          this.alertService.warn(errorMsg);
        });
  }
}
