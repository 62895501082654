import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { AlertMessage } from '../../models/alert-message';
import { AlertService } from '../../services/alert.service';

/**
 * AlertComponent
 * @author Edmilson Silva <ebsilva@indracompany.com>
 */
@Component({
  preserveWhitespaces: false,
  selector: 'afe-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  public msgs: Array<AlertMessage> = [];
  private sub: Subscription;

  constructor(private readonly alertService: AlertService) { }

  /**
   * ngOnInit
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public ngOnInit(): void {
    this.sub = this.alertService.getAlert()
      .subscribe((message: AlertMessage) => {
        if (message) {
          this.msgs.push(message);
        } else {
          this.msgs = [];
        }
      });
  }

  /**
   * ngOnDestroy
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /**
   * track -> by index
   * @param index number
   */
  public track(index: number): number {
    return index;
  }
}
