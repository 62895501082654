<header>
  <div>
    <img id="image_logo" src="assets/logo-f1rst-branco.png" alt="imagem em formato de logo da f1rst."
    default="assets/logo-f1rst-branco.png" />
  </div>

  <div>
    <p id="sub-title">Pesquisa de satisfação - End User Computing</p>
  </div>

</header>


<div class="chat" *ngIf="!finished">
  <div id="messages" class="scrollbar">
    <div class="msg" *ngFor="let item of messages">

      <div *ngIf="item.sender === 'HUMAN'" class="human-message">
        <img src="assets/user.png" alt="user image" width="60" height="60">
        <div class="message-area-human">
          <div class="text" [innerHTML]="item.message"></div>
        </div>
      </div>

      <div *ngIf="item.sender === 'BOT'" class="bot-message">
        <img src="assets/robot.png" alt="robot image" width="60" height="60">
        <div class="message-area-robot">
          <div class="text" [innerHTML]="trustHTML(item.message)"></div>
        </div>
      </div>

    </div>

  </div>
  <div class="form-chat">
    <textarea (keyup.enter)="sendMessage($event)" class="scrollbar" maxlength="1000" id="sendMessage"
      placeholder="Faça uma pergunta..." [ngClass]="{'error': invalidMessage}"></textarea>
    <button id="sendButton" (click)="sendMessage($event)" aria-label="Right Align" aria-hidden="true"
      class="std-ic_send">
    </button>
  </div>
</div>

<div class="chat-form" *ngIf="finished">
  <div class="robot">
    <img src="assets/lazy_img.png" default="assets/smarthelp_warning.png" alt="Robo feliz" title="Robo feliz"
      width="200" height="131" />
  </div>
  <div class="form-message">
    <p class="message">
      Sua pesquisa de satisfação foi encerrada, obrigado por participar.
    </p>
  </div>
</div>
