import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

/**
 * AuthGuard
 * Define as permissões para acessar uma determinada rota
 *
 * @author Edmilson Silva <ebsilva@indracompany.com>
 */
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    public readonly router: Router,
    public readonly authenticationService: AuthenticationService,
  ) { }

  /**
   * canActivate
   * Determina o que é um usuário logado e o que acontece com o
   * usuário não logado
   */
  public async canActivate(): Promise<boolean> {
    if (!this.authenticationService.userValue) {
      await this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
