<afe-spinner [show]="loading" *ngIf="loading;else load"></afe-spinner>
<ng-template #load>
  <form [formGroup]="quickMsgForm" class="form" method="POST" (ngSubmit)="saveQuickMsg()">
    <div class="fields list">
      <div class="form-group">
        <span *ngIf="quickMsgForm.controls.data.value.value.length < 10" class="btn btn-primary"
          (click)="add(quickMsgForm.controls.data,'titleText');show.push(true);">
          Adicionar </span>
        <div class="form-group textarea-list"
          *ngFor="let item of quickMsgForm.controls.data.value.value; let i=index;trackBy: track">
          <div class="edit-mode" *ngIf="show[i]; else title">
            <div>
              <label class="form-control-label">
                Título
                <span class="btn btn-link" (click)="show[i]=false">
                  (-) Ocultar
                </span>
              </label>
              <input class="form-control" type="text" (input)="inputKVC(quickMsgForm.controls.data, i, 'title', title)"
                #title [value]="item.title" />
            </div>
            <div>
              <label class="form-control-label">
                Texto
              </label>
              <textarea class="form-control scrollbar" (input)="inputKVC(quickMsgForm.controls.data, i, 'text', text)"
                #text cols="100" rows="3" [value]="item.text"></textarea>
            </div>
          </div>
          <ng-template #title>
            <label class="form-control-label">
              {{ item.title }}
              <span class="btn btn-link" (click)="show[i]=true">
                (+) Editar
              </span>
            </label>
          </ng-template>
          <div class="actions">
            <span class="btn btn-danger" *ngIf="quickMsgForm.controls.data.value.value.length > 1"
              (click)="remove(quickMsgForm.controls.data, i);show.splice(i,1);">
              Remover
            </span>
          </div>
        </div>
      </div>
    </div>
    <div id="buttons">
      <button class="btn btn-success" (click)="saveQuickMsg()">
        Salvar
      </button>
      <button class="btn btn-secondary" (click)="resetQuickMsg()">
        Reset
      </button>
    </div>
  </form>
</ng-template>
