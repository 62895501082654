import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { QueryParams } from '../../models/query-params';
import { Result } from '../../models/result';
import { SessionResult } from '../../models/session-result';
import { QueueService } from '../../services/queue.service';

/**
 * Teams Page Component
 */
@Component({
  selector: 'afe-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
})
export class TeamsComponent implements OnInit, OnDestroy {

  public protocolForm: FormGroup;
  public sessionId: string;
  public token: string;
  public error: boolean;
  private sub: Subscription;

  constructor(
    public readonly queueService: QueueService,
    public readonly route: Router,
    private readonly aroute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.protocolForm = this.formBuilder.group({
      protocol: ['', Validators.required],
      sigla: ['', Validators.required],
    });
    this.sub = this.aroute.queryParams.subscribe((params: QueryParams) => {
      this.token = params.token;
    });
  }

  /**
   * ngOnDestroy
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /**
   * f
   * Getter to Form Fields
   * @author Edmilson Silva <ebsilva@indracompany.com>
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.protocolForm.controls;
  }

  public checkSession(): void {
    this.error = false;
    const protocol = this.f.protocol.value as string;
    const sigla = (this.f.sigla.value as string).toLocaleUpperCase();

    this.queueService.validateProtocol(protocol, sigla)
      .subscribe(async (res: Result) => {
        if (res.ok) {
          const result = res.result as SessionResult;
          if (result.sessionid) {
            this.sessionId = result.sessionid;
          }
          await this.route.navigate(['queue'], {
            queryParams: this.qs(sigla),
          });
        } else {
          this.error = true;
        }
      }, () => {
        this.error = true;
      });
  }

  /**
   * Query String parameters
   */
  public qs(sigla?: string): QueryParams {
    const qs = new QueryParams();
    if (!this.sessionId) {
      qs.senderid = sigla;
    } else {
      qs.ssid = this.sessionId;
    }
    qs.token = this.token;
    return qs;
  }

}
