import { BackofficeService } from './../services/backoffice.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

/**
 * AuthGuard
 * Define as permissões para acessar uma determinada rota
 *
 * @author Edmilson Silva <ebsilva@indracompany.com>
 */
@Injectable({ providedIn: 'root' })
export class AuthBackofficeGuard implements CanActivate {
  public superadmin: Array<string> = [];

  constructor(
    public readonly router: Router,
    public readonly authenticationService: AuthenticationService,
    public readonly backofficeService: BackofficeService,
  ) {
    const currentUrl = window.location.href; //obtendo a url completa;
    const isIntenalUrl = currentUrl.includes('paas'); //obtendo propriedade da url interna;

    if(isIntenalUrl){
      this.backofficeService.getAdmins(this.authenticationService.userValue)
      .pipe(
        take(1),
        tap((admins: Array<string>) => {
          this.superadmin = admins;
        }),
      )
      .subscribe();
    }
   }

  /**
   * canActivate
   * Determina o que é um usuário logado e o que acontece com o
   * usuário não logado
   */
  public async canActivate(): Promise<boolean> {
    if (
      !this.authenticationService.userValue
    ) {
      await this.router.navigate(['/login']);
      return false;
    } else {
      if (
        !this.authenticationService.userValue.sigla ||
        !this.superadmin.includes(this.authenticationService.userValue.sigla)
      ) {
        await this.router.navigate(['/admin']);
        return false;
      }
    }
    return true;
  }
}
