import { Component } from '@angular/core';

/**
 * 404 Page Component
 */
@Component({
  selector: 'afe-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss'],
})
export class Error404Component {

}
