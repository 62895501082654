import { PipesModule } from '@afe/pipes';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertComponent } from './components/alert/alert.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { FormChannelComponent } from './components/form-channel/form-channel.component';
import { FormGeneralComponent } from './components/form-general/form-general.component';
import { FormIncidentComponent } from './components/form-incident/form-incident.component';
import { FormQuickMessageComponent } from './components/form-quick-message/form-quick-message.component';
import { HeaderComponent } from './components/header/header.component';
import { OnlineComponent } from './components/online/online.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AdminComponent } from './pages/admin/admin.component';
import { BackofficeComponent } from './pages/backoffice/backoffice.component';
import { ChatComponent } from './pages/chat/chat.component';
import { Error404Component } from './pages/error404/error404.component';
import { LoginComponent } from './pages/login/login.component';
import { QueueComponent } from './pages/queue/queue.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { PipesModule as Pipes } from './pipes/pipes.module';
import { NpsComponent } from './pages/nps/nps.component';

/**
 * Main Module of App
 */
@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    ChatMessageComponent,
    QueueComponent,
    Error404Component,
    HeaderComponent,
    LoginComponent,
    AdminComponent,
    AlertComponent,
    TeamsComponent,
    OnlineComponent,
    BackofficeComponent,
    SpinnerComponent,
    FormGeneralComponent,
    FormChannelComponent,
    FormIncidentComponent,
    FormQuickMessageComponent,
    NpsComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    PipesModule,
    Pipes,
    HttpClientModule,
    NgbModule,
    QRCodeModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
