<div id="header" [ngStyle]="{'background-color': user && isOperator && channel && channel.color}">
  <div class="left-area">
    <img id="santander-tec-logo" title="Santander Tecnologia" alt="Santander Tecnologia" width="200" src="assets/santander-logo.png" />
    <img id="header-logo-area" title="End-User Computing and Services" alt="End-User Computing and Services" src="assets/endUser-logo.png" width="190" height="56" />
  </div>
  <div class="right-area">
    Bem-vindo ao Smart Help
    <span *ngIf="(user && isOperator) || (!isOperator && chatUser)">
      ,
    </span>
    <div *ngIf="user && isOperator" ngbDropdown class="d-inline-block">
      <a href="javascript:void(0)" [title]="user.name" ngbDropdownToggle>
        {{ user.name }}
      </a>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button *ngIf="uri === '/backoffice'" ngbDropdownItem (click)="goTo('admin')" aria-label="Right Align"
          aria-hidden="true">Operação</button>
        <button *ngIf="uri === '/admin' && canAccess" ngbDropdownItem (click)="goTo('backoffice')"
          aria-label="Right Align" aria-hidden="true">BackOffice</button>
        <button ngbDropdownItem (click)="logout()" aria-label="Right Align" aria-hidden="true">Sair</button>
      </div>

    </div>
    <span *ngIf="user && isOperator" class="channel">
      {{ user.channel }}
    </span>
    <div *ngIf="!isOperator && chatUser" class="chat-user">
      {{ chatUser.name }}
    </div>
  </div>
</div>
