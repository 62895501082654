import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { FormBase, TitleText } from '../../models/form-base';
import { User } from '../../models/user';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BackofficeService, Settings } from '../../services/backoffice.service';

/**
 * Quick Message settings form
 */
@Component({
  selector: 'afe-form-quick-message',
  templateUrl: './form-quick-message.component.html',
  styleUrls: ['./form-quick-message.component.scss'],
})
export class FormQuickMessageComponent extends FormBase implements OnInit, OnDestroy {

  public quickMsgForm: FormGroup;
  public quickMsgArr: FormArray;
  public quickMsgReset: Array<TitleText>;
  public quickMsgSub: Subscription;
  public setQuickMsg: Subscription[] = [];
  public loading = true;
  public show: Array<boolean> = [];

  private timeout: NodeJS.Timer = null;
  private readonly user: User;

  constructor(
    public readonly formBuilder: FormBuilder,
    public readonly backOfficeService: BackofficeService,
    public readonly alertService: AlertService,
    public readonly authService: AuthenticationService,
  ) {
    super();
    this.user = this.authService.userValue;
  }
  /**
   * when the view starts
   */
  public ngOnInit(): void {
    this.quickMsgSub = this.backOfficeService.getQuickMsg(this.user)
      .pipe(
        take(1),
      )
      .subscribe((configs) => {
        this.show = Array.from({ length: configs.length }, () => false);
        this.show[0] = true;
        this.quickMsgArr = this.formBuilder.array(configs);
        this.quickMsgForm = this.formBuilder.group({
          data: this.formBuilder.control(this.quickMsgArr, Validators.required),
        });
        this.quickMsgReset = (this.quickMsgForm.controls.data.value as FormArray)
          .value as Array<TitleText>;
        this.loading = false;
      }, () => { this.loading = false; });
  }
  /**
   * when the view is destroyed
   */
  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
    if (this.quickMsgSub) {
      this.quickMsgSub.unsubscribe();
    }
    if (this.setQuickMsg.length > 0) {
      this.setQuickMsg.forEach(item => item.unsubscribe());
    }
  }

  /**
   * Save Quick message settings data
   */
  public saveQuickMsg(): void {

    this.alertService.clear();
    this.loading = true;

    const arr = this.quickMsgForm.controls.data.value as FormArray;
    const data = arr.value as Array<Settings>;

    this.setQuickMsg.push(this.backOfficeService.setQuickMsg(this.user, data)
      .pipe(
        take(1),
      )
      .subscribe(
        () => {
          this.loading = false;
          this.quickMsgReset = (this.quickMsgForm.controls.data.value as FormArray)
            .value as Array<TitleText>;
          this.alertService.success('Configurações de Mensagens Rápidas Salva');
          this.timeout = this.timeoutClearMsg(this.alertService);
        },
        () => {
          this.loading = false;
          this.timeout = this.timeoutClearMsg(this.alertService);
          this.alertService.error('Erro ao salvar configurações de mensagens rápidas');
        }
      ));
  }
  /**
   * reset Quick Message settings
   */
  public resetQuickMsg(): void {
    this.quickMsgForm.setValue({
      data: this.formBuilder.array(
        this.quickMsgReset,
      ),
    });
  }

}
