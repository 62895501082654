<div class="teams">
  <afe-header></afe-header>
  <div class="teams-content">
    <div class="access-message">
      <div class="robot">
        <img src="assets/lazy_img.png" default="assets/smarthelp_warning.png" alt="Favor informe seus dados"
          title="Você esta na fila e espera" width="200" height="138" />
        <p class="message">
          Favor, insira abaixo sua matricula e documento para iniciar ou recuperar sua sessão.
        </p>
      </div>
      <form [formGroup]="protocolForm" (ngSubmit)="checkSession()" method="POST">
        <span *ngIf="error && f.protocol.dirty" class="error">
          Este documento ou protocolo não é válido para seu atendimento.
        </span>
        <div class="form-data">
          <div>
            <label for="sigla" class="input-label">
              Matricula
            </label>
            <input class="input" placeholder="Sigla" formControlName="sigla" />
          </div>
          <div>
            <label for="protocol" class="input-label">
              Documento / Protocolo
            </label>
            <input class="input" placeholder="Documento/Protocolo" formControlName="protocol" />
          </div>
          <div>
            <input class="btn btn-danger" value="Enviar" (click)="checkSession()" />
          </div>
        </div>
      </form>
    </div>
  </div>
