<afe-spinner [show]="loading" *ngIf="loading;else load"></afe-spinner>
<ng-template #load>
  <form [formGroup]="generalForm" class="form" method="POST" (ngSubmit)="saveGeneral()">
    <div class="form-group">
      <div class="fields boolean">
        <div class="custom-control custom-switch">
          <input type="checkbox" formControlName="startWithBot" id="startWithBot" class="custom-control-input" />
          <label class="custom-control-label" for="startWithBot">
            Iniciar com o bot (RASAX)
          </label>
        </div>
      </div>
      <div *ngIf="generalForm.controls.startWithBot.value">
        <div class="fields text">
          <label class="form-control-label" for="botName">
            Nome do bot
          </label>
          <input type="text" formControlName="botName" id="botName" class="form-control" />
        </div>
        <div class="form-group">
          <div class="fields boolean">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="sendBotInitialMsg" id="sendBotInitialMsg"
                class="custom-control-input" />
              <label class="custom-control-label" for="sendBotInitialMsg">
                Enviar mensagem de boas vindas
              </label>
            </div>
          </div>
          <div class="fields textarea" *ngIf="generalForm.controls.sendBotInitialMsg.value">
            <label class="form-control-label" for="botInitialMsg">
              Mensagem de boas vindas
            </label>
            <textarea class="form-control txt-lg scrollbar" formControlName="botInitialMsg" cols="100" rows="3"
              id="botInitialMsg">
                    </textarea>
          </div>
        </div>
        <div class="form-group" *ngIf="!generalForm.controls.sendBotInitialMsg.value">
          <div class="fields boolean">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="sendInitialMsg" id="sendInitialMsg"
                class="custom-control-input" />
              <label class="custom-control-label" for="sendInitialMsg">
                Enviar mensagem inicial do usuário
              </label>
            </div>
          </div>
          <div class="fields text" *ngIf="generalForm.controls.sendInitialMsg.value">
            <label class="form-control-label" for="initialMsg">
              Mensagem inicial do usuário
            </label>
            <input type="text" formControlName="initialMsg" id="initialMsg" class="form-control" />
          </div>
          <div class="fields boolean" *ngIf="generalForm.controls.sendInitialMsg.value">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="showInitialMsgOnChat" id="showInitialMsgOnChat"
                class="custom-control-input" />
              <label class="custom-control-label" for="showInitialMsgOnChat">
                Exibir a mensagem inicial no chat
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="fields textarea">
        <label class="form-control-label" for="protocolMsg">
          Mensagem de protocolo
        </label>
        <textarea class="form-control scrollbar" formControlName="protocolMsg" cols="100" rows="3"
          id="protocolMsg"></textarea>
      </div>
      <div class="fields textarea">
        <label class="form-control-label" for="protocolDocMsg">
          Mensagem de protocolo com documento
        </label>
        <textarea class="form-control scrollbar" formControlName="protocolDocMsg" cols="100" rows="3"
          id="protocolDocMsg"></textarea>
      </div>
      <div class="fields boolean">
        <div class="custom-control custom-switch">
          <input type="checkbox" formControlName="protocolMsgUsingSystem" id="protocolMsgUsingSystem"
            class="custom-control-input" />
          <label class="custom-control-label" for="protocolMsgUsingSystem">
            Enviar mensagem de protocolo como o sistema
          </label>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="fields boolean">
        <div class="custom-control custom-switch">
          <input type="checkbox" formControlName="noHumanCheckBeforeRedirect" id="noHumanCheckBeforeRedirect"
            class="custom-control-input" />
          <label class="custom-control-label" for="noHumanCheckBeforeRedirect">
            Verificar se há analista antes de encaminhar
          </label>
        </div>
      </div>
      <div class="fields boolean" *ngIf="generalForm.controls.noHumanCheckBeforeRedirect.value">
        <div class="custom-control custom-switch">
          <input type="checkbox" formControlName="noHumanForceCloseChat" id="noHumanForceCloseChat"
            class="custom-control-input" />
          <label class="custom-control-label" for="noHumanForceCloseChat">
            Encerrar o chat se não houver analistas
          </label>
        </div>
      </div>
      <div class="fields textarea"
        *ngIf="generalForm.controls.noHumanCheckBeforeRedirect.value && !generalForm.controls.noHumanForceCloseChat.value">
        <label class="form-control-label" for="noHumanMsg">
          Mensagem para ausencia de analista
        </label>
        <textarea class="form-control scrollbar" formControlName="noHumanMsg" cols="100" rows="3"
          id="noHumanMsg"></textarea>
      </div>
      <div class="fields boolean"
        *ngIf="generalForm.controls.noHumanCheckBeforeRedirect.value && !generalForm.controls.noHumanForceCloseChat.value">
        <div class="custom-control custom-switch">
          <input type="checkbox" formControlName="noHumanMsgUsingSystem" id="noHumanMsgUsingSystem"
            class="custom-control-input" />
          <label class="custom-control-label" for="noHumanMsgUsingSystem">
            Enviar a mensagem de ausência como o sistema
          </label>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="fields boolean">
        <div class="custom-control custom-switch">
          <input type="checkbox" formControlName="operatorSendGreetingMessage" id="operatorSendGreetingMessage"
            class="custom-control-input" />
          <label class="custom-control-label" for="operatorSendGreetingMessage">
            Enviar mensagem inicial do analista
          </label>
        </div>
      </div>
      <div class="fields textarea" *ngIf="generalForm.controls.operatorSendGreetingMessage.value">
        <label class="form-control-label" for="operatorGreetingMessage">
          Mensagem inicial do analista
        </label>
        <textarea class="form-control txt-md scrollbar" formControlName="operatorGreetingMessage" cols="100" rows="3"
          id="operatorGreetingMessage"></textarea>
      </div>
    </div>
    <div class="form-group">
      <div class="fields array">
        <fieldset formArrayName="channels" class="fieldsets">
          <legend>Canais de acesso do Usuário</legend>
          <span class="btn btn-primary" (click)="add(generalForm.controls.channels)"> + </span>
          <div class="form-group"
            *ngFor="let item of generalForm.controls.channels.value.value; let i=index;trackBy: track">
            <input class="form-control" type="text" (input)="input(generalForm.controls.channels, i, in)" #in
              [value]="item" />
            <span class="btn btn-danger" *ngIf="generalForm.controls.channels.value.value.length > 1"
              (click)="remove(generalForm.controls.channels, i)"> - </span>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="form-group">
      <div class="fields boolean">
        <div class="custom-control custom-switch">
          <input type="checkbox" formControlName="timeout" id="timeout" class="custom-control-input" />
          <label class="custom-control-label" for="timeout">
            Timeout
          </label>
        </div>
      </div>
      <div class="fields number" *ngIf="generalForm.controls.timeout.value">
        <label class="form-control-label" for="timeoutMinutesQueue">
          Tempo em Fila (min)
        </label>
        <input type="number" placeholder="0" id="timeoutMinutesQueue" formControlName="timeoutMinutesQueue"
          class="form-control" min="0" />
      </div>
      <div class="fields number" *ngIf="generalForm.controls.timeout.value">
        <label class="form-control-label" for="timeoutMinutesSessions">
          Atendimento com analista (min)
        </label>
        <input type="number" placeholder="0" id="timeoutMinutesSessions" formControlName="timeoutMinutesSessions"
          class="form-control" min="0" />
      </div>
      <div class="fields number" *ngIf="generalForm.controls.timeout.value">
        <label class="form-control-label" for="timeoutMinutesSessionsBot">
          Atendimento com RasaX (min)
        </label>
        <input type="number" placeholder="0" id="timeoutMinutesSessionsBot" formControlName="timeoutMinutesSessionsBot"
          class="form-control" min="0" />
      </div>
    </div>
    <div id="buttons">
      <button class="btn btn-success" (click)="saveGeneral()">
        Salvar
      </button>
      <button class="btn btn-secondary" (click)="resetGeneral()">
        Reset
      </button>
    </div>
  </form>
</ng-template>
