<afe-header id="header"></afe-header>
<afe-online [status]="online"></afe-online>
<div id="chat">
  <div id="messages" class="scrollbar" (scroll)="newMessages ? onScroll($event.target): ''">
    <afe-chat-message [ngClass]="{'attendant': msg.sender.isAttendant, 'system':msg.sender.sigla ==='XXXXXXX' }"
      [message]="msg" *ngFor="let msg of messages; trackBy: track">
    </afe-chat-message>
    <div *ngIf="messages.length === 0">
      Nenhuma messagem ainda!
    </div>
    <div class="survey" [class.fadeOut]="showSurveyFade" [class.hidden]=" !showSurvey">
      <span>Como você avalia este atendimento?</span>
      <ngb-rating [(rate)]="rating" [starTemplate]="templ" [max]="5" [readonly]="!enabled && surveyMsg && !surveyError"
        (rateChange)="enabled=true">
        <ng-template #templ let-fill="fill" let-index="index">
          <span class="star std-ic_star" [class.filled]="fill === 100"
            [class.nohover]="!enabled && surveyMsg && !surveyError">
          </span>
        </ng-template>
      </ngb-rating>
      <button class="btn btn-success" [disabled]="!enabled" (click)="sendSurvey()">
        <span *ngIf="!surveyMsg || surveyError;else btnTxt">Enviar avaliação</span>
        <ng-template #btnTxt>Avaliação enviada, obrigado</ng-template>
      </button>
      <span class="error" *ngIf="surveyError">
        Erro ao enviar a avaliação, favor tente novamente.
      </span>
    </div>
  </div>
  <div *ngIf="newMessages" id="newMessages" (click)="scrollTo()">
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M0 0h24v24H0V0z" fill="none"></path>
      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
    </svg>
    <p>Novas mensagens</p>
  </div>
  <audio id="notification" autoplay muted>
    <source src="assets/audio/arpeggio-467.ogg" type="audio/ogg" />
    <source src="assets/audio/arpeggio-467.mp3" type="audio/mp3" />
    <source src="assets/audio/arpeggio-467.m4r" type="audio/m4r" />
  </audio>
  <div id="form-chat">
    <textarea class="scrollbar" [attr.maxlength]="charsLimit" id="sendMessage" (keydown)="triggerFn($event)"
      [disabled]="disabled || !online" (input)="triggerFn($event)" placeholder="Faça uma pergunta..."
      [ngClass]="{'error': !check}" #textMessage></textarea>
    <button id="sendButton" [disabled]="disabled" (click)="sendMessage($event)" aria-label="Right Align"
      aria-hidden="true" class="std-ic_send">
    </button>
    <div id="logo-area" *ngIf="token !== '532c67fe1b5afae15d2d08fba7a78de0f63cc4b5'">
      <img src="assets/lazy_img.png" default="assets/logo_end_user_reduzido-1.png" alt="End-User Computing and Services"
        title="End-User Computing and Services" width="200" />
    </div>
    <div class="limit" [ngClass]="{'right': token === '532c67fe1b5afae15d2d08fba7a78de0f63cc4b5'}"
      *ngIf="textMessage.value.length > 0">
      <span class="error" *ngIf="limited; else charsAmount">
        Limite de caracteres atigindo ({{charsLimit}})
      </span>
      <ng-template #charsAmount>
        Tamanho do texto: {{textMessage.value.length}}
        caracter{{textMessage.value.length > 1?'es':''}}
      </ng-template>
    </div>
  </div>
</div>
