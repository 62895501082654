
import { NgModule } from '@angular/core';

import { ClickOutSideDirective } from './click-out-side.directive';
import { ImagePreloadDirective } from './image-preload.directive';

/**
 * Directives/Pipes Module
 * declarações
 * imports
 * e exports
 *
 * @author Edmilson Silva <ebsilva@indracompany.com>
 */
@NgModule({
  imports: [],
  declarations: [
    ImagePreloadDirective,
    ClickOutSideDirective,
  ],
  exports: [
    ImagePreloadDirective,
    ClickOutSideDirective,
  ],
})
export class PipesModule { }
