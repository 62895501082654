<afe-header [isOperator]="true"></afe-header>
<afe-online [status]="online"></afe-online>
<div id="operator">
  <div id="sidebar">
    <div id="customers-in-service">
      <h2>Atendimento ({{ usersInService.length }})</h2>
      <div class="users scrollbar">
        <div class="user-item" [title]="activeUser.user.sigla" *ngFor="let activeUser of usersInService; trackBy: track"
          (click)="add($event, activeUser)" [ngClass]="{'active-tab': checkTab(activeUser)}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1"
            stroke-linecap="round" stroke-linejoin="round">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="8" r="5"></circle>
          </svg>
          <div class="user-data">
            <span class="name">{{activeUser.user.name}}</span>
            <span *ngIf="(activeUser.user.name | uppercase) !== (activeUser.user.sigla | uppercase)" class="penumper">
              {{activeUser.user.sigla}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div id="customers-in-queue">
      <h2>Fila de Espera ({{ usersInQueue.length }})</h2>
      <div class="users scrollbar">
        <div class="user-item" [title]="queueItem.user.sigla" *ngFor="let queueItem of usersInQueue;trackBy: track"
          (click)="changeToService($event, queueItem)">
          <svg xmlns="http://www.w3.org/2000/svg"
            [ngClass]="{'warn':queueItem.status === 1,'danger': queueItem.status === 2}" width="20" height="20"
            viewBox="0 0 24 24" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="8" r="5"></circle>
          </svg>
          <span class="name">
            {{ queueItem.user.name }}
          </span>
          <span class="wait">
            {{ queueItem.wait }}
          </span>
        </div>
        <audio id="queue-notification">
          <source src="assets/audio/beyond-doubt-2-581.ogg" type="audio/ogg" />
          <source src="assets/audio/beyond-doubt-2-581.mp3" type="audio/mp3" />
          <source src="assets/audio/beyond-doubt-2-581.m4r" type="audio/m4r" />
        </audio>
      </div>
    </div>
    <div id="logo-area">
      <img src="assets/lazy_img.png" default="assets/logo_end_user_reduzido-1.png" alt="End-User Computing and Services"
        title="End-User Computing and Services" width="200" routerLink="/" />
    </div>
  </div>
  <div id="chat-area">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [title]="elem.user.sigla" *ngFor="let elem of tabs; trackBy: track" [ngbNavItem]="elem"
        [ngClass]="{'disabled': elem.disabled}">
        <a ngbNavLink (click)="validateActive(elem);scrollTo()">
          <span class="close position-relative pl-2 font-weight-light" (click)="close($event, elem)">×</span>
          {{elem.newMsgs? '*':''}} {{ elem.user.name }}
        </a>
        <ng-template ngbNavContent>
          <div class="chat">
            <div class="messages scrollbar">
              <afe-chat-message class="operator"
                [ngClass]="{'attendant': msg.sender.isAttendant, 'system':msg.sender.sigla ==='XXXXXXX'}"
                [message]="msg" *ngFor="let msg of elem.msg; trackBy: track">
              </afe-chat-message>
              <div *ngIf="elem.msg.length === 0">
                Nenhuma messagem ainda!
              </div>
            </div>
            <div class="s-now" (click)="toggleModal(elem)" title="Informações do incidente no Service Now">
              <img width="48" height="48" title="Informações do incidente no Service Now"
                alt="Informações do incidente no Service Now" src="assets/lazy_img.png"
                default="assets/icone_ticket.png" />
            </div>
            <div id="snow-modal" *ngIf="elem.showModal">
              <div class="modal" *ngIf="elem.incidentData; else failOrLoad">
                <div class="close" (click)="closeModal(elem)">x</div>
                <label for="inc">
                  <span>Incidente</span>
                </label>
                <input readonly type="text" id="inc" [value]="elem.incidentData.id" />
                <label for="link">
                  <span>URL</span>
                </label>
                <input readonly type="text" id="link" [value]="elem.incidentData.url">
                <div class="buttons">
                  <em class="std-ic_copy" (click)="copy()" title="Copiar"></em>
                  <em class="std-ic_iframe" (click)="goTo(elem.incidentData.url)" title="Acessar"></em>
                </div>
              </div>
              <ng-template #failOrLoad>
                <div class="modal">
                  <div class="close" (click)="closeModal(elem)">x</div>
                  <afe-spinner [show]="loading" *ngIf="loading;else load"></afe-spinner>
                  <ng-template #load>
                    O incidente ainda não foi aberto,
                    <div *ngIf="!elem.showIncidentMsg;else showIcidentMsg">
                      Preencha os dados de contato
                      <span class="red" *ngIf="showErrorInc"><br />Erro ao enviar, tente novamente</span>
                      <input type="text" name="name" (input)="elem.user.name=$event.target.value"
                        [value]="elem.user.name || ''" placeholder="Nome" />
                      <input type="text" name="penumper" (input)="elem.user.sigla=$event.target.value"
                        [value]="elem.user.sigla || ''" placeholder="Sigla" />
                      <input type="text" name="phone" (input)="elem.user.phone=$event.target.value"
                        [value]="elem.user.phone || ''" placeholder="Telefone" />
                      <hr />
                      <span *ngIf="blockSend">Enviando...</span>
                      <button class="btn btn-success float-right" [disabled]="blockSend" (click)="sendInc(elem)">
                        <em class="std-ic_check"></em>
                        Enviar
                      </button>
                    </div>
                    <ng-template #showIcidentMsg>
                      <br />Tente novamente em breve.
                    </ng-template>
                  </ng-template>
                </div>
              </ng-template>
            </div>
            <div class="form">
              <em class="std-ic_help tip" ngbTooltip="Dicas de Edição de texto

Negrito use **palavra** ou __palavra__
Itálico use *palavra* ou _palavra_
Para links use
 [Texto do link](www.santander.com.br)
 Ou https://www.santander.com.br"></em>
              <div *ngIf="quickMsgs.length > 0" class="quick_msg" title="Mensagens Rápidas"
                (click)="toggleQuickMessages()" (clickOutside)="quickMessages=false">
                <div *ngIf="quickMessages" [style.margin-top.px]="(quickMsgs.length * -27)-19" class="teste">
                  <ul>
                    <ng-container *ngFor="let quick of quickMsgs; last as isLast">
                      <li class="quick-item" (click)="quickMsg($event, elem.sessionid)" [title]="quick.text">
                        {{quick.title}}
                      </li>
                      <li *ngIf="!isLast" class="divisor"></li>
                    </ng-container>
                  </ul>
                </div>
                <em class="std-ic_quick_message"></em>
              </div>
              <textarea [disabled]="elem.disabled" class="scrollbar" [attr.maxlength]="charsLimit" id="sendMessage"
                (keydown)="triggerFn($event, elem.sessionid)" (input)="triggerFn($event, elem.sessionid)"
                placeholder="Converse com o usuário..." [ngClass]="{'error':
                !check}" #textMessage></textarea>
              <button id="sendButton" [disabled]=" elem.disabled" (click)="sendMessage($event, elem.sessionid)"
                aria-label="Right Align" aria-hidden="true" class="std-ic_send">
              </button>
            </div>
            <div class="limit" *ngIf="textMessage.value.length > 0">
              <span class="error" *ngIf="limited; else charsAmount">
                Limite de caracteres atigindo ({{charsLimit}})
              </span>
              <ng-template #charsAmount>
                Tamanho do texto: {{textMessage.value.length}}
                caracter{{textMessage.value.length > 1?'es':''}}
              </ng-template>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
